import {
    FiEdit,
    FiChevronDown,
    FiTrash,
    FiShare,
    FiPlusSquare,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import cats from "../Categorie/cats";
import Iconfrecciagiu from "../../../assets/Icons/Iconfrecciagiu";

const CategorieDropdownRegalo = ({
    isOpen,
    setIsOpen,
    opennth,
    micro,
    setMicro,
}) => {
    const [open, setOpen] = useState(false);
    const [macro, setMacro] = useState(null);
    const selectall = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(opennth);
    };

    return (
        <div className="flex items-center w-full ">
            <motion.div
                animate={open ? "open" : "closed"}
                className="relative w-full"
            >
                <span className="font-regular text-sm">
                    {"Scegli una categoria"}
                </span>
                <button
                    onClick={handleClick}
                    className={`flex items-center h-10  w-full gap-2 px-3 py-2 rounded-md text-verdeScuro bg-background
                     hover:ring-1 ring-verdeScuro transition-colors`}
                >
                    {micro !== null ? micro : "Categorie"}
                </button>

                <motion.ul
                    initial={wrapperVariants.closed}
                    variants={wrapperVariants}
                    transition={{ duration: 0.001 }}
                    style={{ originY: "top", translateX: "-50%" }}
                    className="grid grid-cols-1 w-full z-40  p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[50%]  overflow-hidden"
                >
                    {macro == null ? (
                        <div className="">
                            {cats.map((item, i) => {
                                return (
                                    <Option
                                        key={i}
                                        prop={item.macro}
                                        setOpen={setOpen}
                                        setMacro={setMacro}
                                        Icon={item.img}
                                        macro={macro}
                                        micro={micro}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div className="flex flex-col w-full">
                            <div
                                onClick={() => {
                                    setMacro(null);
                                }}
                                className="text-lg space-x-4 capitalize w-full flex justify-between pr-2 items-center cursor-pointer hover:bg-verdeScuro/10 py-2 rounded-md"
                            >
                                <div className="flex justify-start pl-3 space-x-3 text-black">
                                    <Iconfrecciagiu
                                        className={"w-4 opacity-75"}
                                    />{" "}
                                    <p>{macro}</p>
                                </div>
                            </div>
                            <div className="">
                                {cats.map((item, i) => {
                                    if (item.macro === macro) {
                                        return item.micro.map((micros, idx) => {
                                            return (
                                                <Optionmicro
                                                    key={idx}
                                                    prop={micros}
                                                    setOpen={setOpen}
                                                    setMacro={setMacro}
                                                    macro={macro}
                                                    setMicro={setMicro}
                                                    micro={micro}
                                                    handleClick={handleClick}
                                                />
                                            );
                                        });
                                    }
                                    return null; // Return null if the condition is not met to avoid warnings
                                })}
                            </div>
                        </div>
                    )}

                    <div className="md:flex hidden items-center col-span-2 gap-2 mt-2 justify-stretch">
                        <p
                            onClick={() => {
                                handleClick();
                            }}
                            className="w-full h-full cursor-pointer text-center hover:bg-verdeScuro/10 py-3 rounded-lg"
                        >
                            Chiudi
                        </p>
                    </div>
                </motion.ul>
            </motion.div>
        </div>
    );
};

const Option = ({ prop, Icon, setOpen, setMacro, macro, micro }) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMacro(prop);
            }}
            className="flex z-40 capitalize relative text-sm items-center justify-between  gap-2 w-full py-1.5 px-3 whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <div className="flex items-center text-sm space-x-3">
                <div className="w-6">{Icon}</div>
                <span>{prop}</span>
            </div>
            <div
                className={` space-x-5 items-center`}
            >
                <span>
                    <Iconfrecciagiu className={"w-5 opacity-75 rotate-180 "} />
                </span>
            </div>
        </motion.li>
    );
};
const Optionmicro = ({
    prop,
    Icon,
    setOpen,
    setMacro,
    macro,
    setMicro,
    micro,
    handleClick,
}) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                setMicro(prop);
                handleClick();
            }}
            className="flex z-40 relative items-center text-sm justify-between gap-2 w-full p-2  whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <span>{prop}</span>
            <input
                className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                type="checkbox"
                name=""
                id=""
                checked={micro === prop}
            />
        </motion.li>
    );
};

export default CategorieDropdownRegalo;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
        },
    },
    closed: {
        opacity: 0,
        y: -15,
        transition: {
            when: "afterChildren",
        },
    },
};

const actionIconVariants = {
    open: { scale: 1, y: 0 },
    closed: { scale: 0, y: -7 },
};
