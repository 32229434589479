import React, { useEffect, useState } from "react";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";
import Punti from "../../assets/Punti";
import Moment from "react-moment";
import ProfilePic from "../../Components/Atoms/ProfilePic/ProfilePic";
import DragCloseDrawer from "../../Components/Atoms/Drawers/DragCloseDrawer";
import PopupCloseDrawer from "../../Components/Atoms/Drawers/PopupCloseDrawer";
import { db } from "../../firebase";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    increment,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import ProfilePicName from "../../Components/Atoms/ProfilePic/ProfilePicName";
import Iconfrecciagiu from "../../assets/Icons/Iconfrecciagiu";
import ModRegalo from "../../Components/Atoms/Modals/ModRegalo";
import CardInfoRegalo from "../../Components/Atoms/Card/InfoRegalo";
import { assignPresent } from "../../utils/PresentUtils";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosClose } from "react-icons/io";
import RegaloSiIcon from "../../assets/Icons/RegaloSi";
import ModRegaloPhone from "../../Components/Atoms/Modals/ModRegaloPhone";
import { calcolaDistanza } from "../../utils/calcolaDistanza";
import InfoDonatore from "../../Components/Atoms/Card/InfoDonatore";

import useDeviceDetection from "../../utils/useDeviceDetection";

function ProdottoAperto({ user, logged = true }) {
    const slug = window.location.href.split("/").pop();
    const [isOpen, setIsOpen] = useState(false);
    const [distanza, setDistanza] = useState(0);
    const [imgN, setImgN] = useState(0);
    const [imgCount, setImgCount] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [data, setData] = useState([]);
    const [img, setImg] = useState([]);
    const [owner, setOwner] = useState([]);
    const [assegna, setAssegna] = useState(null);
    const [showConfermaAssegnazione, setshowConfermaAssegnazione] = useState(false);
    const [regaliUtente, setRegaliUtente] = useState([]);
    const [isOpenImg, setIsOpenImg] = useState(false);
    const device = useDeviceDetection();

    const handleLoad = async () => {
        const docRef = doc(db, "regali", slug);
        const doc1 = await getDoc(docRef);
        let ownerId;
        if (doc1.exists) {
            console.log("Document data:", doc1.data());
            const date = { ...doc1.data(), id: doc1.id };
            setData(date);
            const imgArray = Array.isArray(date.img) ? date.img : [];
            setImg(
                imgArray.filter(
                    (item) => typeof item === "string" && item.trim().length > 0
                )
            );

            const docRef2 = doc(db, "users", date.owner);
            const doc2 = await getDoc(docRef2);
            if (doc2.exists) {
                setOwner({ ...doc2.data(), id: doc2.id });
                setLoaded(true);
                ownerId = doc2.id;
                setDistanza(
                    await calcolaDistanza(
                        user.lat,
                        user.lng,
                        doc2.data().lat,
                        doc2.data().lng
                    )
                );
            }
        }

        const otherRef = query(
            collection(db, "regali"),
            where("owner", "==", ownerId)
        );
        const otherDocsSnapshot = await getDocs(otherRef);

        setRegaliUtente(
            otherDocsSnapshot.docs.map((item) => ({
                ...item.data(),
                id: item.id,
            }))
        );
        setLoaded2(true);
    };

    const getUser = async () => {
        try {
        } catch (error) { }
    };

    const handleAssegna = async () => {
        if (!assegna) {
            return;
        }

        try {
            await assignPresent(assegna, data.id);
        } catch (e) {
            console.log(e);
        } finally {
            // window.location.reload();
        }
    };

    useEffect(() => {
        if (!loaded) {
            handleLoad();
        }
    }, []);
    const handleClick = (segno) => {
        console.log(imgCount, img.length);
        if (segno === "+") {
            if (imgCount === img.length - 1) {
                setImgCount(0);
            } else {
                setImgCount(imgCount + 1);
            }
        } else {
            if (imgCount === 0) {
                setImgCount(img.length - 1);
            } else {
                setImgCount(imgCount - 1);
            }
        }
    };

    const popupContent = (
        <div className="flex flex-col gap-5 relative z-10">
            <div className="bg-white w-16 h-16 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
                <RegaloSiIcon />
            </div>
            <h3 className="text-3xl font-bold text-center">
                Confermi l'assegnazione?
            </h3>
            <p className="text-center px-4 sm:px-0">
                Vuoi davvero assegnare questo item?
            </p>
            <div className="flex px-4 gap-6 justify-center items-center">
                <p className="underline">Assegnato a:</p>
                <div className="flex gap-2 justify-center">
                    <ProfilePic userId={assegna} w={10} />
                    <ProfilePicName
                        textStyle={"text-black font-semibold text-lg"}
                        userId={assegna}
                    />
                </div>
            </div>

            <div className="flex flex-col-reverse mt-6 sm:flex-row gap-2">
                <button
                    onClick={() => setshowConfermaAssegnazione(false)}
                    className="bg-transparent hover:bg-white/10 transition-colors text-black font-semibold w-full py-2 rounded-lg"
                >
                    No, torna indietro
                </button>
                <button
                    onClick={handleAssegna}
                    className=" hover:opacity-90 transition-opacity w-full bg-verde rounded-lg text-white text-lg font-medium text-center p-4"
                >
                    Conferma!
                </button>
            </div>
        </div>
    );

    return (
        <div className="md:pt-1 md:mb-10 mb-24">
            {loaded ? (
                <div className="">
                    <div className="grid grid-cols-9 gap-5 w-full">
                        <div className="md:col-span-6 col-span-9">
                            <div className="flex md:flex-row flex-col-reverse relative">
                                {img.length > 0 ? (
                                    <div className="flex md:flex-col md:w-20 w-14 shrink-0  justify-start items-start md:pl-5 md:mt-0 mt-2  mr-3 gap-3">
                                        {img.map((item, index) => (
                                            <img
                                                key={index}
                                                className={
                                                    "rounded-lg aspect-square object-cover cursor-pointer "
                                                }
                                                onClick={() =>
                                                    setImgCount(index)
                                                }
                                                src={item}
                                                alt={data.titolo}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="pl-5"></div>
                                )}
                                <div className="grow md:mx-0 -mx-4 relative">
                                    <button
                                        onClick={() => window.history.back()}
                                        className="absolute md:hidden top-2 z-10 left-2  aspect-square  text-white  rounded-full p-2"
                                    >
                                        <Iconfrecciagiu
                                            className={
                                                "w-8 rotate-0 stroke-white"
                                            }
                                        />
                                    </button>
                                    <div
                                        onClick={() => setIsOpenImg(true)}
                                        className="absolute md:hidden w-full h-full bg-gradient-to-t from-transparent via-transparent to-verdeScuro/70"
                                    ></div>
                                    <div onClick={() => setIsOpenImg(true)}>
                                        <img
                                            className=" md:rounded-lg aspect-square sm:aspect-video w-screen bg-opacity-5 object-cover cursor-pointer"
                                            src={img[imgCount]}
                                            alt={data.titolo}
                                        />
                                    </div>
                                    <SpringModal
                                        isOpen={isOpenImg}
                                        setIsOpen={setIsOpenImg}
                                        img={img}
                                        count={imgCount}
                                        handleClick={handleClick}
                                    />
                                </div>
                            </div>
                            <div className="bg-white md:text-md text-sm md:mt-5 mt-2 md:p-3 p-2 rounded-lg">
                                <div className="flex justify-between">
                                    <p className="md:text-2xl text-xl font-semibold">
                                        {data.titolo}
                                    </p>
                                    <div className="md:block hidden">
                                        <ModRegalo user={user} regalo={data} />
                                    </div>
                                    <div className="block md:hidden">
                                        <ModRegaloPhone
                                            user={user}
                                            regalo={data}
                                        />
                                    </div>
                                </div>
                                <CardInfoRegalo
                                    microCategoria={data.microcategoria}
                                    logged={logged}
                                    disponibilita={data.disponibilita}
                                    //distanza={distanza}
                                    condizioni={data.condizioni}
                                    createdAt={data.createdAt}
                                    descrizione={data.descrizione}
                                />
                            </div>
                        </div>
                        <div className="md:col-span-3 col-span-9 flex  h-full flex-col gap-5">
                            <div className="bg-white md:block hidden p-3 rounded-lg">
                                <InfoDonatore owner={owner} />
                                <div className="w-full h-[2px] my-2 bg-verdeScuro/10" />
                                <div className="flex w-full h-14 items-center justify-between ">
                                    <div className="flex justify-center items-center gap-2">
                                        <div className="flex h-8 w-8 object-contain">
                                            <Punti />
                                        </div>
                                        <p className="text-3xl font-semibold">
                                            100
                                        </p>
                                    </div>
                                </div>
                                <p className="font-semibold">
                                    Prenotati:{" "}
                                    <span className="font-normal">
                                        {data.prenotati.length}
                                    </span>
                                </p>
                            </div>
                            {data.assegnato ? (
                                <div className="bg-white rounded-lg p-3">
                                    <div className="flex justify-between items-center w-full">
                                        <p className="text-lg font-semibold">
                                            Assegnato a:
                                        </p>
                                        <button
                                            className="bg-verde rounded-lg text-white px-2 py-1"
                                            onClick={() =>
                                            (window.location.href =
                                                "/profilo/" +
                                                data.assegnato)
                                            }
                                        >
                                            Vai al profilo
                                        </button>
                                    </div>
                                    <div className="w-full h-[2px] my-2 bg-verdeScuro/10" />
                                    <div className="flex items-center space-x-2">
                                        <ProfilePic userId={data.assegnato} />
                                        <ProfilePicName
                                            userId={data.assegnato}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col justify-center items-center space-x-2 bg-white rounded-lg p-3">
                                    <div className="flex justify-between items-center w-full">
                                        <p className="text-lg font-semibold">
                                            Assegna a:
                                        </p>
                                        <button
                                            onClick={() => setshowConfermaAssegnazione(true)}
                                            className={`bg-verde rounded-lg text-white px-2 py-1 ${!assegna && 'hidden'}`}
                                        >
                                            Assegna
                                        </button>
                                    </div>
                                    <div className="w-full h-[2px] my-2 bg-verdeScuro/10" />
                                    <div className="flex flex-col w-full items-start  text-black space-y-2">
                                        {data.prenotati.map((item, i) => {
                                            return (
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        onClick={() =>
                                                            setAssegna(item)
                                                        }
                                                        checked={
                                                            assegna === item
                                                        }
                                                        type="checkbox"
                                                        className="rounded-md checked:bg-verde checked:border-transparent"
                                                        name=""
                                                        id=""
                                                    />
                                                    <ProfilePic userId={item} />
                                                    <ProfilePicName
                                                        userId={item}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <Caricamento />
            )}

            {device === "Mobile" ? (
                <DragCloseDrawer open={showConfermaAssegnazione} setOpen={setshowConfermaAssegnazione}>
                    {popupContent}
                </DragCloseDrawer>
            ) : (
                <PopupCloseDrawer open={showConfermaAssegnazione} setOpen={setshowConfermaAssegnazione}>
                    {popupContent}
                </PopupCloseDrawer>
            )}

        </div>
    );
}

export default ProdottoAperto;
/*
                                <div className="grid grid-cols-2">
                                    <div></div>
                                    <button type="button"
                                        className="w-44 h-12 justify-self-end self-end focus:outline-none text-white bg-rosso hover:bg-verde focus:ring-4 focus:ring-verde/30 font-medium rounded-lg  "
                                        onClick={() => window.location.href = '/prenota/' + data._id}>
                                        Prenotati
                                    </button>
                                </div>
*/

const SpringModal = ({ isOpen, setIsOpen, img, count, handleClick }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="backdrop-blur  fixed inset-0 z-50 grid place-items-center  cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "0deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex justify-center items-center bg-white text-white p-2  w-full h-full shadow-xl cursor-default relative overflow-hidden"
                    >
                        <IoIosClose
                            onClick={() => setIsOpen(false)}
                            className="w-5 h-5 absolute right-5 top-5 bg-verdeScuro hover:bg-verde fill-white rounded-full cursor-pointer"
                        />
                        <button
                            className="rounded-full w-10 h-10 bg-white absolute top-1/2 -translate-y-1/2 left-5 flex justify-center items-center"
                            onClick={() => handleClick("-")}
                        >
                            <Iconfrecciagiu className=" w-8 h-8 rotate-0 text-verdeScuro cursor-pointer" />
                        </button>
                        <div className="h-full">
                            <img
                                src={img[count]}
                                className="object-contain h-full w-full"
                                alt="Immagine non caricata correttamente"
                            />
                        </div>
                        <button
                            className="rounded-full w-10 h-10 bg-white absolute top-1/2 -translate-y-1/2 right-5 flex justify-center items-center"
                            onClick={() => handleClick("+")}
                        >
                            <Iconfrecciagiu className=" w-8 h-8 rotate-180 text-verdeScuro cursor-pointer" />
                        </button>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

