import React from "react";

function BottoneGrigio2({ text, onclick }) {
	return (
		<button
			type="button"
			onClick={() => onclick()}
			className="py-2 px-2 w-full text-center text-verdeScuro border border-verdeScuro hover:bg-verdeScuro/30  focus:ring-4 focus:outline-none focus:ring-verde/30 font-semibold rounded-lg text-base  flex items-center justify-center"
		>
			{text}
		</button>
	);
}

export default BottoneGrigio2;
