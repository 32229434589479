import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    runTransaction,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { getChatFolder, sendMessage } from "./ChatUtils";
import axios from "axios";

export function getNotificationsQuery() {
    return query(
        collection(db, "users/" + auth.currentUser.uid + "/notifications"),
        orderBy("createdAt", "desc")
    );
}

/**
 * @typedef {Object} Notification
 * @property {string} title
 * @property {string} content
 * @property {string} sender - The id of the sender
 * @property {string} link - An internal link, triggered on click of notification. e.g. /chat
 */

/**
 *
 * @param {string} userId - The user to send the notification to
 * @param {Notification} notification
 * @param {import("./ChatUtils").Chat|null} chat
 */

export async function readNotifications() {
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    await updateDoc(userDocRef, {
        hasNotifications: false,
    });
}

export async function hasNotifications(userId) {
    if (!userId) {
        throw new Error("User ID is not provided");
    }

    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
        throw new Error("User document does not exist");
    }

    return userDoc.data().hasNotifications;
}


export async function postNotification(userId, notification, chat = null) {
    console.log("Posting notification");
    
    try {
        await runTransaction(db, async (transaction) => {
            const userDocRef = doc(db, "users", userId);
            const notificationDocRef = collection(
                db,
                "users",
                userId,
                "notifications"
            );
            const headers = {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            };

            // Leggi il documento utente
            const userDoc = await transaction.get(userDocRef);
            const user = userDoc.data();

            // Aggiungi la notifica
            const newNotificationRef = doc(notificationDocRef);
            transaction.set(newNotificationRef, {
                createdAt: new Date(),
                title: notification.title,
                content: notification.content,
                sender: notification.sender,
                link: notification.link,
            });

            // Aggiorna il documento dell'utente
            transaction.update(userDocRef, {
                hasNotifications: true,
            });

            console.log("Notification added");

            // Invia la mail di notifica
            console.log("Sending notification email");
            await axios.post(
                process.env.REACT_APP_API_URL + "/mail/notifications",
                { user: user, notification: notification },
                { headers }
            ).then((res) => {
                console.log(res.data);
            });
           

            if (chat) {
                await sendMessage(
                    chat.id,
                    userId,
                    notification.content,
                    null,
                    true
                );
                console.log("Message sent");
            }
        });
    } catch (e) {
        console.error("Transaction failed: ", e);
    }
}


