import React from 'react'

import StellaPiena from "../../../assets/Icons/StellaPiena";
import Stella from "../../../assets/Icons/Stella";

export const ToggleFavourite = ({ isSelected, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`aspect-square flex p-2 justify-center items-center h-full min-w-6 md:min-w-8 rounded-lg text-white text-4xl font-medium `}
        >
            {isSelected ? (
                 <span className="min-w-6 md:min-w-8 ">
                <StellaPiena />
            </span>
            ) : (
                <span className="min-w-6 md:min-w-8 text-[#aaa]">
                    {" "}
                    <Stella />
                </span>
            )}
        </button>
    )
}