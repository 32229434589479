import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      className="w-full"
      viewBox="0 0 157.47 157.47"
      
    >
      <g data-name="Livello 1">
        <circle cx="78.74" cy="78.74" r="78.74" className="cls-1 fill-rosso text-rosso stroke-none"></circle>
        <path
          d="M108.36 80.33c-.68-.67-1.91-1.02-3.71-1.05l-26.93-.41-.22 14.26 11.09.17-.11 6.97c-2.07.6-4.19.89-6.35.85-5.12-.08-9.15-1.74-12.08-4.98-2.94-3.24-4.36-7.74-4.27-13.49.07-4.8 1.65-8.62 4.73-11.45 3.08-2.83 7.13-4.21 12.14-4.13 1.79.03 3.55.23 5.26.59 1.71.37 3.1.77 4.18 1.21 1.07.44 2.08.93 3.03 1.47.94.54 1.56.94 1.84 1.18.29.24.51.42.67.53l8.95-15.71-1.1-.81c-.68-.59-1.79-1.28-3.33-2.07-1.55-.79-3.33-1.58-5.35-2.38-2.02-.79-4.55-1.49-7.57-2.1-1.32-.26-2.66-.46-4.02-.62l4.08-5a6.482 6.482 0 00-.93-9.13 6.495 6.495 0 00-9.14.93l-3.17 3.89L73 35.06c-2.18-2.85-6.26-3.39-9.1-1.21a6.487 6.487 0 00-1.21 9.1l4.93 6.44c-1.24.32-2.45.7-3.63 1.14-4.78 1.78-8.74 4.24-11.88 7.38-3.14 3.15-5.58 6.81-7.34 10.98-1.75 4.17-2.67 8.63-2.74 13.39-.07 4.75.7 9.24 2.33 13.46 1.63 4.22 3.96 7.96 7 11.2 3.04 3.24 6.92 5.82 11.65 7.74 4.72 1.92 9.96 2.92 15.72 3.01 7.55.12 14.09-1.08 19.62-3.58 5.53-2.5 9.08-5.12 10.66-7.84l.34-22.17c.03-1.85-.3-3.11-.97-3.78z"
          className="cls-2 fill-white stroke-none"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;