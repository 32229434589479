import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 105.28 110.06"
      fill="CurrentColor"
    >
      <path
        d="M96.61 81.52c6.17-9.35 9.15-20.21 8.61-31.43-.62-13.05-6.1-25.36-15.42-34.67C80.47 6.11 68.15.65 55.1.05 40.34-.61 25.88 4.98 15.43 15.43 4.98 25.89-.62 40.35.06 55.11c.6 13.02 6.04 25.31 15.31 34.63 9.27 9.32 21.54 14.81 34.56 15.47 8.59.43 17.21-1.25 24.96-4.87l17.99 9.12c.77.39 1.64.6 2.49.6 1.19 0 2.33-.38 3.3-1.11a5.464 5.464 0 002.1-5.42l-4.15-22.01zM25.77 45.78c0-.09-.01-.17-.02-.23v-.05-.28c.29-6.3 5.49-11.27 11.85-11.27s11.56 4.97 11.84 11.31v.14c.01.09 0 .19 0 .29-.01.2-.02.31-.03.41l-.06.24c-.05.21-.09.33-.13.45l-.09.19c-.06.13-.13.28-.22.42l-.11.15c-.07.1-.18.25-.31.39l-.1.09c-.15.15-.29.26-.43.35-.38.26-.8.45-1.26.54l-.18.02c-.14.02-.33.05-.53.05h-.42c-1.32 0-2.43-.99-2.62-2.35-.02-.05-.04-.18-.06-.32-.16-1.85-.92-3.32-2.21-4.27l-.22-.16c-.15-.1-.25-.17-.35-.24-.03-.02-.08-.06-.15-.09-.2-.1-.42-.21-.65-.29-.23-.08-.47-.15-.72-.21l-.21-.05a4.48 4.48 0 00-.74-.09h-.09c-.22 0-.45.03-.72.08l-.22.05c-.24.06-.47.13-.71.21-.24.08-.45.18-.67.3-.05.03-.11.06-.16.1-.06.05-.16.11-.26.18l-.28.21c-1.28.95-2.04 2.42-2.2 4.24-.01.16-.04.29-.07.41a2.64 2.64 0 01-2.61 2.29h-.42c-.2 0-.4-.02-.59-.06-.58-.11-1-.29-1.38-.56-.16-.11-.31-.24-.44-.37l-.07-.06c-.14-.15-.26-.3-.37-.45l-.08-.11c-.08-.12-.15-.27-.22-.43l-.08-.16c-.05-.13-.09-.26-.12-.39-.04-.13-.06-.21-.07-.29-.02-.1-.02-.21-.03-.31zM79.32 60.6c-3.37 11.89-14.34 20.19-26.67 20.19s-23.3-8.3-26.67-20.19a2.97 2.97 0 01.5-2.62 3.04 3.04 0 012.45-1.22h47.44c.97 0 1.87.45 2.45 1.22.57.76.75 1.71.5 2.62zm-.78-12.68c-.66.68-1.55 1.06-2.5 1.06h-.18c-1.53 0-2.78-1.15-2.91-2.67-.16-1.85-.92-3.32-2.21-4.27l-.14-.1c-.2-.15-.39-.3-.62-.42-.18-.09-.36-.17-.56-.24-.6-.23-1.12-.35-1.6-.37s-.2 0-.2 0c-.22 0-.45.03-.73.09l-.22.05c-.24.06-.48.13-.71.21-.25.09-.49.19-.73.32-.05.03-.1.06-.15.1-.07.05-.14.11-.22.16-1.59 1.02-2.5 2.61-2.5 4.36 0 .77-.29 1.5-.81 2.02-.49.49-1.15.77-1.85.77h-.42c-.96 0-1.85-.38-2.51-1.07-.66-.69-1-1.6-.96-2.55.29-6.39 5.49-11.4 11.85-11.4s11.63 5.02 11.85 11.43c.03.95-.32 1.85-.98 2.53z"
        className="cls-1"
        data-name="layer 1"
      ></path>
    </svg>
  );
}

export default Icon;