import React from "react";

function BottoneVerde2({ text, onclick, fullWidth }) {

	return (
		<button
			type="button"
			onClick={() => onclick()}
			className={`py-2 px-4 text-center text-white border border-verde hover:bg-verdeScuro bg-verde rounded-lg flex items-center justify-center ${fullWidth && 'w-full'}`}
		>
			{text}
		</button>
	);
}

export default BottoneVerde2;
