import {
    FiEdit,
    FiChevronDown,
    FiTrash,
    FiShare,
    FiPlusSquare,
} from "react-icons/fi";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

const StaggeredDropDown = ({
    titolo,
    opzioni,
    isOpen,
    setIsOpen,
    opennth,
    checkedItems,
    setCheckedItems,
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCheckedItems([]);
    }, []);
	
    const handleCheck = (text) => {
        setCheckedItems((prev) => {
            if (prev.includes(text)) {
                return prev.filter(item => item !== text);
            } else {
                return [...prev, text];
            }
        });
    };

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(opennth);
    };

    const clearChecks = () => {
        setCheckedItems([]);
    };

    return (
        <div className="flex items-center text-sm ">
            <motion.div animate={open ? "open" : "closed"} className="relative">
                <button
                    onClick={() => handleClick()}
                    className={`flex items-center gap-2 px-3 py-2 rounded-md text-verdeScuro border-2 ${
                        checkedItems.length > 0 ? "bg-grigino" : "bg-white"
                    } border-transparent transition-colors`}
                >
                    <span className="font-medium  text-sm"> <nobr>{titolo}</nobr> </span>
                    <motion.span className="md:block hidden" variants={iconVariants}>
                        <FiChevronDown />
                    </motion.span>
                </button>

                <motion.ul
                    initial={wrapperVariants.closed}
                    variants={wrapperVariants}
                    transition={{ duration: 0.001 }}
                    style={{ originY: "top" }}
                    className="md:flex hidden  z-40 flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-0 min-w-48 overflow-hidden"
                >
                    {opzioni.map((item, i) => {
                        return (
                            <Option
                                key={i}
                                prop={item}
                                setOpen={setOpen}
                                checked={checkedItems.includes(item.text)}
                                onCheck={() => handleCheck(item.text)}
                            />
                        );
                    })}
                    <button
                        onClick={clearChecks}
                        className="w-full text-right pr-2 text-verdeScuro/50 text-sm hover:underline hover:text-verdeScuro cursor-pointer py-1 rounded-md"
                    >
                        Cancella filtri
                    </button>
                </motion.ul>
            </motion.div>
        </div>
    );
};

const Option = ({ prop, Icon, setOpen, checked, onCheck }) => {
    const check = useRef(null);
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                onCheck();
            }}
            className={`flex z-40 ${checked ? "bg-grigino": "bg-white"} relative items-center gap-2 w-full p-2 text-sm whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer`}
        >
            <input
                
                className="cursor-pointer hidden rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                type="checkbox"
                name=""
                id=""
                checked={checked}
            />
            {prop.imgPresent && <span className="w-5">{prop.img()}</span>}
            <span>{prop.text}</span>
        </motion.li>
    );
};

export default StaggeredDropDown;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
        },
    },
    closed: {
        opacity: 0,
        y: -15,
        transition: {
            when: "afterChildren",
        },
    },
};

const actionIconVariants = {
    open: { scale: 1, y: 0 },
    closed: { scale: 0, y: -7 },
};
