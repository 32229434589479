import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Chisiamo from "../../Components/Molecules/Chisiamo/Chisiamo";
import axios from "axios";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import Faq from "../../assets/Icons/Faq";

function Funziona() {
    const [openQuestion, setOpenQuestion] = useState(null);

    const handleToggle = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className="md:px-12 px-7 pt-1">
            <div className="mt-8 pt-2">
              
                <h1 className="font-malik md:text-6xl mt-5 text-center text-5xl text-verdeScuro font-bold w-full">
                    Come funziona?{" "}
                </h1>

                <div className="md:text-center gap-10 mt-10">
                    <p className="inline-block font-light">
                        In questa sezione ti spiegheremo come utilizzare al
                        meglio la nostra piattaforma semplice e organizzata. La
                        prima cosa da sapere è che su Togiftit è tutto gratis e
                        nessun utente dovrà pagare nulla. Di seguito scoprirai
                        come fare una donazione, utilizzare la chat e gestire le
                        tue attività. Qualcosa non è chiaro? <br /> Se hai
                        voglia di farne due chiacchiere contatta il nostro
                        servizio di assistenza via mail:
                        assistenza@togiftit.com.
                    </p>
                </div>
            </div>
            <div className="mb-10">
                <Question
                    title="A cosa servono i punti gift?"
                    index={0}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        I punti gift, che chiamiamo "moneta della generosità",
                        servono a incentivare comportamenti corretti e a evitare
                        pratiche scorrette come prenotazioni eccessive o la
                        rivendita degli oggetti ricevuti. Ogni donazione vale
                        100 punti. Quando ti prenoti per un oggetto, vengono
                        temporaneamente sottratti 100 punti fino a quando il
                        donatore non assegna l'oggetto. Se non verrai scelto
                        come assegnatario, i punti ti verranno restituiti; in
                        caso contrario, saranno trasferiti al donatore.
                        Tranquillo, se sarai un buon gifter verrai ricompensato
                        lungo il percorso con tanti punti per continuare a
                        ricevere regali.
                        <br />
                        Presto scoprirai{" "}
                        <span className="font-bold">
                            che con questi punti ci saranno molte sorprese!
                        </span>
                    </p>
                </Question>
                <Question
                    title="Come Fare una Donazione"
                    index={1}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        1. Premi sul tasto rosso in alto "Fai un regalo". <br />
                        2. Inserisci le foto dell'oggetto. <br />
                        3. Scegli una categoria. <br />
                        4. Inserisci le condizioni dell'oggetto. <br />
                        5. Indica la disponibilità per il ritiro. <br />
                        6. Dai un titolo al regalo. <br />
                        7. Aggiungi una descrizione esplicativa riguardo
                        all'oggetto. <br />
                    </p>
                </Question>
                <Question
                    title="Come Funziona la Chat"
                    index={2}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        La chat è divisa in due sezioni:{" "}
                        <span className="font-bold">"Adozioni"</span> e{" "}
                        <span className="font-bold"> "Donazioni"</span>, così
                        potrai gestire al meglio le tue attività.
                        <br />
                        <br />
                        Adozioni
                        <br />
                        • Troverai le chat filtrabili per le donazioni a cui sei
                        interessato, prenotato, quelle ricevute oppure
                        annullate. <br />
                        <b>
                            • Ogni donazione creerà una chat univoca, anche nei
                            confronti di utenti contattati in precedenza.
                        </b>
                        <br />• Accorda il ritiro con il donatore. <br />
                        • Conferma il ritiro una volta effettuato. <br />•
                        Recensisci il donatore per aiutare i prossimi Gifters!
                        <br />
                        <br />
                        Donazioni
                        <br />
                        • Troverai le chat divise per donazione e filtrabili per
                        i gifter che hanno chiesto informazioni, che sono
                        prenotati, a cui hai assegnato o annullato una donazione
                        <br />
                        • Assegna la donazione a chi ritieni più adatto
                        direttamente dalla chat.
                        <br />
                        • Conferma la consegna una volta effettuata
                        <br />
                        • Recensisci il Gifter.
                        <br />
                        • C'è un limite di 20 prenotazioni ricevibili per ogni
                        donazione.
                        <br />• Una volta assegnato il regalo non sarà più
                        prenotabile.
                    </p>
                </Question>
                <Question
                    title="Come Gestire le Proprie Attività"
                    index={3}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        Nella tua pagina profilo troverai la sezione "Donazioni"
                        dove potrai gestire: <br />
                        • Donazioni fatte <br />
                        • Donazioni assegnate <br />
                        • Donazioni da assegnare <br />
                        • Donazioni ricevute <br />• Donazioni a cui ti sei
                        prenotato e sei in attesa <br />
                    </p>
                </Question>
                <Question
                    title="Prenotazione e Ricerca dei Regali"
                    index={4}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        Nella home di togiftit, potrai cercare oggetti in regalo
                        in modo semplice e intuitivo. Utilizza i filtri per
                        trovare esattamente ciò che ti serve:
                        <br />
                        1. Ricerca per Nome: Inserisci il nome dell'oggetto che
                        stai cercando nella barra di ricerca.
                        <br />
                        2. Ricerca per Categoria: Scegli tra le varie categorie
                        disponibili per restringere la ricerca agli oggetti di
                        tuo interesse.
                        <br />
                        3. Ricerca per Condizioni: Filtra gli oggetti in base
                        alle loro condizioni (nuovo, usato, ecc.).
                        <br />
                        4. Ricerca per Disponibilità di Ritiro: Verifica la
                        disponibilità per il ritiro degli oggetti.
                        <br />
                        All'interno della pagina del regalo, troverai tutte le
                        informazioni necessarie sull'oggetto, comprese le sue
                        caratteristiche, condizioni e la disponibilità per il
                        ritiro. Sarà sufficiente un click per: <br />
                        • Prenotarsi: Riserva l'oggetto per te. <br />
                        • Chiedere Informazioni: Contatta il donatore per
                        ulteriori dettagli sull'oggetto. <br />
                        La nostra piattaforma è pensata per semplificare il
                        processo di ricerca e prenotazione, permettendoti di
                        trovare rapidamente ciò di cui hai bisogno. Buona
                        ricerca su To Gift It!
                    </p>
                </Question>
                <Question
                    title="Gestione delle Assegnazioni delle Donazioni"
                    index={5}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        <span className="font-bold">
                            Cosa succede quando assegno una donazione?
                        </span>{" "}
                        <br />
                        Una volta assegnato un oggetto, questo rimarrà visibile
                        sulla piattaforma, ma sarà contrassegnato come
                        "Assegnato". In questo modo, nessun altro utente potrà
                        prenotarsi o contattarti per questo regalo. <br />
                        <span className="font-bold">
                            E se cambio idea dopo aver assegnato una donazione?
                        </span>{" "}
                        <br />
                        Nessun problema. Se decidi di annullare l'assegnazione
                        di un oggetto, vai nella chat con l'utente a cui hai
                        assegnato l'oggetto, premi il pulsante "Non posso più
                        consegnare l'oggetto", rispondi al form che apparirà e
                        l'assegnazione verrà annullata.
                    </p>
                </Question>
                <Question
                    title="Per modificare i miei dati personali?"
                    index={6}
                    openQuestion={openQuestion}
                    handleToggle={handleToggle}
                >
                    <p>
                        Puoi modificare le informazioni del tuo profilo
                        accedendo alla pagina dedicata. All'interno della
                        sezione "Profilo" troverai opzioni per aggiornare le tue
                        informazioni personali e cambiare la password. Conferma
                        i tuoi dati attuali, apporta le modifiche necessarie e
                        salva le modifiche.
                    </p>
                </Question>
            </div>
        </div>
    );
}

export default Funziona;

const Question = ({ title, children, index, openQuestion, handleToggle }) => {
    const isOpen = openQuestion === index;

    return (
        <motion.div
            animate={isOpen ? "open" : "closed"}
            className="border-b-[1px] border-b-slate-300"
        >
            <button
                onClick={() => handleToggle(index)}
                className="py-6 w-full flex items-center justify-between gap-4"
            >
                <motion.span
                    variants={{
                        open: {
                            color: "rgba(3, 6, 23, 0)",
                        },
                        closed: {
                            color: "rgba(3, 6, 23, 1)",
                        },
                    }}
                    className="text-lg font-bold text-left bg-verde bg-clip-text"
                >
                    {title}
                </motion.span>
                <motion.span
                    variants={{
                        open: {
                            rotate: "180deg",
                            color: "#9fc909",
                        },
                        closed: {
                            rotate: "0deg",
                            color: "#030617",
                        },
                    }}
                >
                    <FiChevronDown className="text-2xl" />
                </motion.span>
            </button>
            <motion.div
                initial={false}
                animate={{
                    height: isOpen ? "fit-content" : "0px",
                    marginBottom: isOpen ? "24px" : "0px",
                }}
                className="overflow-hidden text-slate-600"
            >
                {children}
            </motion.div>
        </motion.div>
    );
};
