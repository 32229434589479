import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 179.7 184.21"
      width={w}
    >
      <path
      fill="CurrentColor"
        d="M169.27 69.52L95 9.68a6.45 6.45 0 00-8.05-.06L10.5 69.47c-2.05 1.6-2.83 4.23-1.98 6.69.85 2.46 3.08 4.05 5.68 4.05h9.57v89.28c0 3.57 2.9 6.47 6.47 6.47H149.3c3.57 0 6.47-2.9 6.47-6.47V80.21h9.73c2.58 0 4.81-1.57 5.67-4.01.86-2.44.11-5.06-1.9-6.68zM155.03 73c-3.57 0-6.47 2.9-6.47 6.47v89.28H30.98V79.47c0-3.57-2.9-6.47-6.47-6.47h-6.83l73.25-57.34L162.1 73h-7.07z"
        className="cls-1"
      ></path>
      <path
      fill="CurrentColor"
        d="M53.41 102.66c1.25.22 2.36-.14 3.05-.97.32-.39.47-.79.57-1.09.13-.39.25-.78.37-1.17.55-1.76 1.02-3.29 1.99-4.64 1.16-1.62 2.73-2.84 4.41-3.42.42-.14.82-.26 1.18-.35l.39-.09c.57-.12 1.04-.17 1.47-.17h.14c.47 0 .92.05 1.42.16l.33.08c.49.12.9.24 1.3.38.5.17.93.38 1.2.52.14.07.25.14.3.17l.14.09c.14.1.27.19.41.28l.48.35c1.1.8 2.04 1.89 2.72 3.16.32.59.52 1.33.73 2.12.26.94.52 1.91 1.07 2.94l.05.09c.59 1.04 1.58 1.74 2.66 1.87.12.01.24.02.36.02.96 0 1.77-.48 2.41-1.42l.55-.99.21-1.35v-.11c-.4-8.82-7.59-15.73-16.39-15.73-6.39 0-12.24 3.72-14.9 9.49-.53 1.15-.92 2.35-1.17 3.59l-.06.3c-.28 1.29-.7 3.24.41 4.66.5.65 1.25 1.06 2.21 1.23zm.35-1.97zM53.76 112.82c-2.62 0-5.02 1.2-6.6 3.29a8.088 8.088 0 00-1.35 7.14c5.56 19.6 23.64 33.28 43.98 33.28s38.42-13.69 43.98-33.28a8.1 8.1 0 00-1.34-7.13 8.231 8.231 0 00-6.61-3.3H53.76zm73.07 8.46c-4.68 16.51-19.91 28.04-37.04 28.04s-32.36-11.53-37.04-28.04c-.1-.37.03-.66.17-.83.21-.28.5-.43.85-.43h72.05c.35 0 .64.15.85.43.19.25.24.53.16.82zM96.76 101.53c.66.96 1.48 1.45 2.43 1.45.12 0 .23 0 .35-.02 1.09-.13 2.08-.83 2.67-1.88l.09-.17c.51-.96.77-1.93 1.02-2.87.21-.78.41-1.52.73-2.12.68-1.26 1.62-2.36 2.72-3.16l.41-.3c.2-.14.34-.23.42-.29l.17-.11c.07-.05.19-.12.31-.18.28-.14.71-.35 1.2-.53.4-.14.81-.26 1.26-.37l.35-.08c.54-.11.99-.16 1.45-.17h.14c.44 0 .91.06 1.44.17l.39.09c.4.1.81.22 1.21.36 1.69.59 3.26 1.8 4.42 3.42.97 1.36 1.44 2.88 1.99 4.64.12.39.24.78.37 1.16.1.31.25.71.57 1.1.69.83 1.8 1.19 3.06.97.96-.17 1.7-.58 2.21-1.23 1.1-1.42.68-3.37.41-4.66l-.06-.3c-.25-1.24-.64-2.45-1.17-3.59-2.66-5.76-8.51-9.49-14.9-9.49-8.8 0-16 6.91-16.39 15.69v.15l.2 1.35.53.95z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;