import React from "react";

function Icon({ w = 25}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="layer 1"
            viewBox="0 0 179.7 184.21"
            width={w}
            className="stroke-none"
        >
            <path
                fill="#2d302b"
                d="M160.67 132.2c-7.62-13.71-15.76-32.28-15.76-43.28 0-43.54-23.41-57.68-39.24-62.26v-2.37c0-8.72-7.1-15.82-15.82-15.82s-15.82 7.1-15.82 15.82v2.37C58.2 31.24 34.79 45.38 34.79 88.92c0 11-8.14 29.57-15.77 43.28-2.36 4.24-2.3 9.27.16 13.45 2.47 4.2 6.85 6.7 11.73 6.7h32.52c1.62 13.3 12.79 23.39 26.42 23.39s24.8-10.09 26.42-23.39h32.52c4.87 0 9.26-2.51 11.72-6.7 2.46-4.18 2.52-9.21.17-13.45zm-70.82 35.99c-9.26 0-16.96-7.34-18.93-16.06h37.86c-1.97 8.72-9.67 16.06-18.93 16.06zm64.45-26.2a6.338 6.338 0 01-5.51 3.15H30.92c-2.29 0-4.35-1.18-5.51-3.15a6.284 6.284 0 01-.08-6.29C29.24 128.66 42 104.49 42 88.92c0-39.15 19.83-52.05 36.46-55.97l2.78-.66v-8c0-4.75 3.86-8.61 8.61-8.61s8.61 3.86 8.61 8.61v8l2.78.66c16.64 3.93 36.47 16.83 36.47 55.97 0 15.58 12.76 39.75 16.67 46.78a6.284 6.284 0 01-.08 6.29z"
                className="stroke-none"
            ></path>
        </svg>
    );
}

export default Icon;
