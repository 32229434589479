import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import BottoneGrigio from "../Bottoni/BottoneGrigio";
import { IoIosClose } from "react-icons/io";
import Icon from "../../../assets/Icons/RegaloSi";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import DragCloseDrawer from "../Drawers/DragCloseDrawer";

const Benvenuto = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const device = useDeviceDetection();

    useEffect(() => {
        if (user.firstLogin) {
            setIsOpen(true);
        }
    }, [user]);

    const handleClose = async (e) => {
        setIsOpen(false);
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, {
            firstLogin: false,
        })
            .then(() => {
                if (e) {
                    window.location.href = "/chisiamo";
                }
            })
            .catch((error) => {
                console.error("Error updating document:", error);
            });
    };

    const welcomeMessage = (
        <div className="w-full flex px-4 flex-col text-center gap-5">
            <div className=" text-verde w-12 h-12 mb-2 rounded-full grid place-items-center mx-auto">
                <Icon />
            </div>
            <h1 className="md:text-5xl text-4xl font-malik text-center text-verdeScuro font-bold">
                <span className="font-bold">Benvenuto!</span>
                <br />
            </h1>
            <div className="flex flex-col text-base space-y-3 mt-5 ">
                <p className="">
                    Da ora anche tu sei un
                    <span className="font-bold pl-1">Gifter!</span>
                    <br />
                    <br />
                    I Gifter sono eroi moderni che combattono lo spreco,
                    aiutano le persone e promuovono il riciclo.
                    <br />
                    <br />
                    Unisciti alla nostra missione. :)
                    <br />
                    <br />
                    Scopri di più su di noi e inizia la tua
                    <span className="font-bold pl-1">
                        avventura sostenibile!
                    </span>
                </p>
            </div>
        </div>
    )
    const welcomeButton = (
        <div className="flex justify-center mt-8">
            <div className="w-full md:w-auto">
                <BottoneGrigio
                    text={"Chi siamo"}
                    onclick={() => handleClose(true)}
                />
            </div>
        </div>
    )
    const mobileBody = (
        <DragCloseDrawer open={isOpen} setOpen={setIsOpen}>
            <div className="flex flex-col relative h-full py-4 px-4 max-w-xl bg-white rounded-lg overflow-hidden justify-around">
                {welcomeMessage}
                {welcomeButton}
            </div>
        </DragCloseDrawer>
    )
    const desktopBody = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => handleClose(false)}
            className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
        >
            <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden"
            >
                <div className="flex justify-end w-full">
                    <IoIosClose
                        onClick={() => handleClose(false)}
                        className="text-3xl cursor-pointer"
                    />
                </div>
                <div className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden">
                    {welcomeMessage}
                    {welcomeButton}
                </div>
            </motion.div>
        </motion.div>
    )
    const body = device === "Mobile" ? mobileBody : desktopBody

    return (
        <AnimatePresence>
            {isOpen && body}
        </AnimatePresence>
    );
};

export default Benvenuto;
