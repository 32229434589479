import { motion } from "framer-motion";

const GlowButton = ({ onclick }) => {
    return (
        <motion.button
            onClick={(e) => {
                e.stopPropagation();
                onclick();
            }}
            className="text-white font-medium px-3 py-2 rounded-md overflow-hidden relative transition-transform "
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            animate={{
                scale: [1, 1.1, 0.9, 1], // Aggiunge un effetto di "bumping"
                transition: {
                    duration: 0.9,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "easeInOut",
                    repeatDelay: 10, // Aggiungi una pausa di 2 secondi tra ogni ciclo
                },
            }}
        >
            <nobr className="relative z-10">Fai un regalo</nobr>
            <div className="bg-rosso absolute z-0 inset-0 w-[400%]"></div>
        </motion.button>
    );
};

export default GlowButton;
