import React, { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import {
    doc,
    getDoc,
    setDoc,
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    runTransaction,
} from "firebase/firestore";

import Google from "../../assets/Icons/Google";
import LogoBianco from "../../assets/Logos/LOGO_WHITE";
import axios from "axios";

function Register({ user = false }) {
    const [data, setData] = useState({
        comune: "",
        punti: 500,
        livello: 0,
        nome: "",
        cognome: "",
        password: "",
        password2: "",
        mail: "",
        telefono: "",
        nascita: "",
        indirizzo: "",
        citta: "",
        provincia: "",
        regione: "",
        lat: "",
        lng: "",
        terms: false,
        codice: "",
        firstLogin: true,
    });
    const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
    const [inviteId, setInviteId] = useState(null);
    const [error, setError] = useState("");
    const [selectedPlace, setSelectedPlace] = useState(null);

    useEffect(() => {
        // Estrai l'ID dell'invito dalla query string
        const params = new URLSearchParams(window.location.search);
        const invite = params.get("invite");
        if (invite) {
            console.log("Invito:", invite);
            setInviteId(invite);
        }

        const unsubscribe = auth.onAuthStateChanged(async (utente) => {
            if (utente) {
                setData((prevData) => ({
                    ...prevData,
                    mail: utente.email,
                    uid: utente.uid,
                }));
                setIsGoogleSignUp(true);
                const docRef = doc(db, "users", utente.uid);
                const docData = await getDoc(docRef);
                if (docData.exists()) {
                    window.location.href = "/";
                }
            }
        });
        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const setIndirizzo = (place) => {
        try {
            const ind = place.formatted_address;
            const components = place.address_components;
            const citta =
                components.find((component) =>
                    component.types.includes("locality")
                )?.long_name || "";
            const provincia =
                components.find((component) =>
                    component.types.includes("administrative_area_level_2")
                )?.long_name || "";
            const regione =
                components.find((component) =>
                    component.types.includes("administrative_area_level_1")
                )?.long_name || "";
            const comune =
                components.find((component) =>
                    component.types.includes("sublocality")
                )?.long_name || citta; // Usa la città come fallback se non trovi il comune
            setData((item) => ({
                ...item,
                indirizzo: ind,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                citta: citta,
                provincia: provincia,
                regione: regione,
                comune: comune,
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const isPhoneUnique = async (phone) => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("telefono", "==", phone));
        const querySnapshot = await getDocs(q);
        return querySnapshot.empty; // Ritorna true se il telefono è unico, false altrimenti
    };

    const validateForm = async () => {
        if (!data.nome) {
            setError("nome");
            return false;
        }
        if (!data.cognome) {
            setError("cognome");
            return false;
        }
        if (!data.telefono) {
            setError("telefono");
            return false;
        }
        const phoneIsUnique = await isPhoneUnique(data.telefono);
        if (!phoneIsUnique) {
            setError("phone-already-in-use");
            return false;
        }
        if (!data.lat || !data.lng || !data.indirizzo) {
            setError("indirizzo");
            return false;
        }
        if (!data.terms) {
            setError("terms-unchecked");
            return false;
        }
        if (!data.nascita) {
            setError("nascita");
            return false;
        }

        const userAge = calculateAge(new Date(data.nascita));
        if (userAge < 18) {
            setError("underage");
            return false;
        }

        return true;
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return age;
    };

    const signInWithMail = async (e) => {
        e.preventDefault();
        setError("");
        const { mail, password, password2, codice, ...userData } = data;

        if (data.password !== data.password2) {
            setError("password-mismatch");
            return;
        }

        const isValid = await validateForm();
        if (!isValid) return;

        let totalPoints = userData.punti;

        // Aggiungi i punti del codice di benvenuto, se fornito e valido
        if (codice) {
            try {
                const codeDocRef = doc(db, "codici_benvenuto", codice);
                const codeDoc = await getDoc(codeDocRef);

                if (!codeDoc.exists()) {
                    setError("code-invalid");
                    return;
                }

                const codeData = codeDoc.data();
                totalPoints += codeData.punti;
            } catch (error) {
                setError("code-invalid");
                return;
            }
        }

        // Aggiungi i punti dell'invito, se presente
        if (inviteId) {
            totalPoints += 100;
            setData((prevData) => [...prevData, { invitedBy: inviteId }]);
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                mail,
                password
            );
            const user = userCredential.user;
            console.log("Utente registrato:", user);
            if (inviteId) {
                const inviterDocRef = doc(db, "users", inviteId);
                const inviterDoc = await getDoc(inviterDocRef);
                console.log(inviterDoc.data());
                if (inviterDoc.exists()) {
                    const inviterData = inviterDoc.data();
                    const newPunti = (inviterData.punti || 0) + 50;
                    await updateDoc(inviterDocRef, {
                        punti: newPunti,
                    });
                }
            }
            const headers = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            };
            const sendmail = await axios.post(
                process.env.REACT_APP_API_URL + "/mail/benvenuto",
                { nome: userData.nome, email: mail },
                { headers }
            );
            console.log(sendmail);
            await setDoc(doc(db, "users", user.uid), {
                ...userData,
                mail: mail,
                uid: user.uid,
                punti: totalPoints,
                createdAt: new Date(),
            });
            // Aggiungi i punti all'invitante
            window.location.href = "/";
        } catch (error) {
            console.error("Errore nella registrazione:", error.message);
            setError(error.code);
        } finally {
        }
    };

    const signUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            setIsGoogleSignUp(true);

            console.log("Utente registrato con Google:", user);

            setData((prevData) => ({
                ...prevData,
                mail: user.email,
                uid: user.uid,
            }));

            setIsGoogleSignUp(true);
        } catch (error) {
            console.error(
                "Errore nella registrazione con Google:",
                error.message
            );
            setError(error.code);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        const isValid = await validateForm();
        if (!isValid) return;

        let totalPoints = data.punti;

        try {
            await runTransaction(db, async (transaction) => {
                // Aggiungi i punti del codice di benvenuto, se fornito e valido
                if (data.codice) {
                    const codeDocRef = doc(db, "codici_benvenuto", data.codice);
                    const codeDoc = await transaction.get(codeDocRef);

                    if (!codeDoc.exists()) {
                        throw new Error("code-invalid");
                    }

                    const codeData = codeDoc.data();
                    totalPoints += codeData.punti;
                }

                // Aggiungi i punti dell'invito, se presente
                if (inviteId) {
                    totalPoints += 100;
                }

                // Crea o aggiorna l'utente
                const userDocRef = doc(db, "users", data.uid);
                transaction.set(userDocRef, {
                    ...data,
                    mail: data.mail,
                    punti: totalPoints,
                    createdAt: new Date(),
                });

                // Aggiungi i punti all'invitante
                if (inviteId) {
                    const inviterDocRef = doc(db, "users", inviteId);
                    const inviterDoc = await transaction.get(inviterDocRef);

                    if (inviterDoc.exists()) {
                        const inviterData = inviterDoc.data();
                        const newPunti = (inviterData.punti || 0) + 50;
                        transaction.update(inviterDocRef, {
                            punti: newPunti,
                        });
                    }
                }
            });

            window.location.href = "/";
        } catch (error) {
            if (error.message === "code-invalid") {
                setError("code-invalid");
            } else {
                console.error("Errore nell'invio dei dati:", error.message);
                setError(error.code);
            }
        }
    };

    const getErrorMessage = (field) => {
        switch (error) {
            case "auth/invalid-email":
                return field === "mail" ? "Email non valida" : "";
            case "auth/email-already-in-use":
                return field === "mail" ? "Email già in uso" : "";
            case "auth/weak-password":
                return field === "password" ? "Password troppo debole" : "";
            case "indirizzo":
                return field === "indirizzo"
                    ? "Inserisci e seleziona un indirizzo"
                    : "";
            case "password-mismatch":
                return field === "password" || field === "password2"
                    ? "Le password non coincidono"
                    : "";
            case "terms-unchecked":
                return field === "terms"
                    ? "Devi accettare i termini e le condizioni"
                    : "";
            case "nome":
                return field === "nome" ? "Inserisci il tuo nome" : "";
            case "cognome":
                return field === "cognome" ? "Inserisci il tuo cognome" : "";
            case "phone-already-in-use":
                return field === "telefono"
                    ? "Numero di telefono già in uso"
                    : "";
            case "telefono":
                return field === "telefono"
                    ? "Inserisci il tuo numero di telefono"
                    : "";
            case "code-invalid":
                return field === "codice"
                    ? "Codice di benvenuto non valido"
                    : "";
            case "invito-non-valido":
                return field === "invito" ? "Invito non valido" : "";
            case "underage":
                return field === "nascita"
                    ? "Devi essere maggiorenne per registrarti"
                    : "";
            case "nascita":
                return field === "nascita"
                    ? "Inserisci la tua data di nascita"
                    : "";
            default:
                return "";
        }
    };

    const handlePlaceSelected = (place) => {
        setSelectedPlace(place);
        setIndirizzo(place);
    };

    return (
        <div className="flex flex-col relative justify-center items-center h-[120%] mb-12 w-full">
            <div className="absolute md:px-6 flex justify-center md:pt-8 h-full w-full top-0 left-0 z-0  focus:ring-0">
                <img
                    className="md:w-[70%] md:h-[100%] w-full h-full  md:rounded-lg object-cover"
                    src={require("../../assets/default/filigrana.jpg")}
                    alt=""
                />
            </div>
            <div className=" z-10 md:my-16 my-10 gap-10 md:w-1/2 md:mx-0 mx-2 flex flex-col ">
                <div className="flex justify-center z-10">
                    <div className="w-56">
                        <LogoBianco />
                    </div>
                </div>
                <div className="flex bg-white shadow-lg pt-5 rounded-lg flex-col w-full mt-0 md:px-16 px-3 items-start">
                    {isGoogleSignUp ? (
                        <div className="mb-5 w-full">
                            <div className="flex justify-center">
                                <div className="w-8">
                                    <Google />
                                </div>
                            </div>
                            <p className="text-verdeScuro mt-5 font-semibold font-light text-base text-center">
                                Per completare la registrazione con Google,
                                inserisci i dati mancanti
                            </p>
                        </div>
                    ) : (
                        <div className="flex flex-col items-start">
                            <h2 className="text-4xl mb-1">Registrati</h2>
                            <p className="text-sm font-light">
                                Hai già un account?{" "}
                                <a
                                    className="text-rosso hover:underline"
                                    href="/login"
                                >
                                    Accedi
                                </a>
                            </p>
                            <button
                                className="bg-verdeScuro mt-5 flex space-x-4 items-center rounded-lg px-4 py-2 font-semibold text-white"
                                onClick={signUpWithGoogle}
                            >
                                <span className="w-6">
                                    <Google />
                                </span>
                                <p>Continua con google</p>
                            </button>
                            <div className="my-3">
                                <p className="text-verdeScuro font-light text-lg text-center">
                                    oppure
                                </p>
                            </div>
                        </div>
                    )}
                    <form
                        onSubmit={
                            isGoogleSignUp ? handleSubmit : signInWithMail
                        }
                        className="grid grid-cols-2 mt-2 w-full gap-3"
                    >
                        <div className="flex flex-col space-y-0">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="mail"
                            >
                                Email
                            </label>
                            <input
                                disabled={isGoogleSignUp}
                                value={data.mail}
                                onChange={handleChange}
                                type="email"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("mail")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="mail"
                                id="mail"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("mail")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("mail")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="password"
                            >
                                Password
                            </label>
                            <input
                                disabled={isGoogleSignUp}
                                placeholder={isGoogleSignUp ? "********" : ""}
                                value={data.password}
                                onChange={handleChange}
                                type="password"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("password")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="password"
                                id="password"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("password")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("password")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="nome"
                            >
                                Nome
                            </label>
                            <input
                                value={data.nome}
                                onChange={handleChange}
                                type="text"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("nome")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="nome"
                                id="nome"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("nome")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("nome")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="password2"
                            >
                                Conferma Password
                            </label>
                            <input
                                disabled={isGoogleSignUp}
                                placeholder={isGoogleSignUp ? "********" : ""}
                                value={data.password2}
                                onChange={handleChange}
                                type="password"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("password2")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="password2"
                                id="password2"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("password2")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("password2")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="cognome"
                            >
                                Cognome
                            </label>
                            <input
                                value={data.cognome}
                                onChange={handleChange}
                                type="text"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("cognome")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="cognome"
                                id="cognome"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("cognome")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("cognome")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="telefono"
                            >
                                Telefono
                            </label>
                            <input
                                value={data.telefono}
                                onChange={handleChange}
                                type="tel"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("telefono")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="telefono"
                                id="telefono"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("telefono")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("telefono")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="nascita"
                            >
                                Data di Nascita
                            </label>
                            <input
                                value={data.nascita}
                                onChange={handleChange}
                                type="date"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("nascita")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="nascita"
                                id="nascita"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("nascita")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("nascita")}
                            </p>
                        </div>
                        <div className="flex flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="codice"
                            >
                                Codice di benvenuto
                            </label>
                            <input
                                value={data.codice}
                                onChange={handleChange}
                                type="text"
                                className={`px-2 py-1 text-md rounded-lg ${
                                    getErrorMessage("codice")
                                        ? "border-2 border-red-500"
                                        : "bg-background border-none"
                                }`}
                                name="codice"
                                id="codice"
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("codice")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("codice")}
                            </p>
                        </div>

                        <div className="flex col-span-2 flex-col space-y-0 ">
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="citta"
                            >
                                Posizione
                            </label>
                            <ReactGoogleAutocomplete
                                apiKey={
                                    "AIzaSyDQ9eD1kvMthn2cOXACyzT71fKNoOANPBU"
                                }
                                style={{ width: "100%" }}
                                name="indirizzo"
                                className={`rounded-lg bg-gray-50 border text-gray-900 focus:ring-rosso focus:border-rosso block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 ${
                                    getErrorMessage("indirizzo")
                                        ? "border-2 border-red-500"
                                        : "border-none"
                                }`}
                                onPlaceSelected={async (e) =>
                                    await handlePlaceSelected(e)
                                }
                                placeholder="Via..."
                                language="it"
                                options={{
                                    types: ["address"],
                                    componentRestrictions: {
                                        country: "it",
                                    },
                                }}
                                onChange={(e) => {
                                    !e.currentTarget.value &&
                                        setData({
                                            ...data,
                                            indirizzo: "",
                                        });
                                }}
                            />
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("indirizzo")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("indirizzo")}
                            </p>
                        </div>

                        <div className="flex col-span-2 items-center space-x-2">
                            <input
                                checked={data.terms}
                                onChange={handleChange}
                                type="checkbox"
                                className={`px-2 py-2 text-sm bg-background rounded-lg ${
                                    getErrorMessage("terms")
                                        ? "border-2 border-red-500"
                                        : "border-none"
                                }`}
                                name="terms"
                                id="terms"
                            />
                            <label
                                className="font-light text-sm text-verdeScuro"
                                htmlFor="terms"
                            >
                                Accetto i{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="hover:underline text-rosso"
                                    href="https://www.iubenda.com/termini-e-condizioni/67170133"
                                >
                                    Termini & Condizioni
                                </a>
                            </label>
                            <p
                                className={`text-red-500 ${
                                    getErrorMessage("terms")
                                        ? "visible"
                                        : "invisible"
                                }`}
                            >
                                {getErrorMessage("terms")}
                            </p>
                        </div>

                        <div className="flex mb-5 col-span-2 justify-center mt-5">
                            <button
                                type="submit"
                                className="px-20 py-2 bg-rosso text-white font-light rounded-lg"
                            >
                                Registrati
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Register;
