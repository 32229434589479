import React from "react";

function BottoneVerde2({ text, onclick }) {
const handleClick = () =>{
	
}

	return (
			<button
				type="button"
				onClick={() => onclick()}
				
				className="py-2 px-6 text-center hover:text-verdeScuro text-verdeScuro border border-verdeScuro hover:bg-verdeScuro/30 focus:ring-4 focus:outline-none focus:ring-rosso/30 font-semibold rounded-lg text-lg  flex items-center justify-center"
			>
				{text}
			</button>
	);
}

export default BottoneVerde2;
