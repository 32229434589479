import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { MdOutlineReport } from "react-icons/md";
import RegaloArrabbiato from "../../../assets/Icons/RegaloArrabbiato";

const Segnala = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="">
            <button
                onClick={() => setIsOpen(true)}
                className="bg-grigino px-3 py-2 rounded-md flex items-center gap-2"
            >
                <div className="w-6 text-rosso">
                    <RegaloArrabbiato />
                </div>
                Segnala utente{" "}
            </button>
            <SpringModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                nome={user.nome}
                cognome={user.cognome}
            />
        </div>
    );
};

const SpringModal = ({ isOpen, setIsOpen, nome, cognome }) => {
    const [selectedReason, setSelectedReason] = useState("");

    const handleReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className=" backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className=" bg-background p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
                    >
                        <div className="relative z-10">
                            <div className="bg-white text-rosso w-12 h-12 mb-2 rounded-full grid place-items-center mx-auto">
                                <RegaloArrabbiato />
                            </div>
                            <h3 className="text-3xl font-bold text-center mb-2">
                                Segnala {nome} {cognome}
                            </h3>
                            <div class="flex flex-col gap-2 my-5">
                                <label
                                    for="reason"
                                    class="text-sm text-secondary"
                                >
                                    Seleziona il motivo per cui vorresti
                                    segnalare questo utente:
                                </label>
                                <select
                                    id="reason"
                                    name="reason"
                                    onChange={handleReasonChange}
                                    className="bg-verdeScuro/10 w-full placeholder:text-sm focus:ring-rosso border-0 appearance-none rounded-lg placeholder:text-verdeScuro/50 "
                                >
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Commenti sessisti"
                                    >
                                        Commenti sessisti
                                    </option>
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Molestia"
                                    >
                                        Molestia
                                    </option>
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Commenti inappropriati o offensivi"
                                    >
                                        Commenti inappropriati o offensivi
                                    </option>
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Commenti razzisti"
                                    >
                                        Commenti razzisti
                                    </option>
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Account falso"
                                    >
                                        Account falso
                                    </option>
                                    <option
                                        className="appearance-none hover:bg-verdeScuro/20"
                                        value="Altro"
                                    >
                                        Altro
                                    </option>
                                </select>
                            </div>
                            <div className="flex gap-2">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="bg-verdeScuro text-white hover:bg-verdeScuro/10 hover:text-black transition-colors  font-semibold w-full py-2 rounded"
                                >
                                    Annulla
                                </button>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="bg-white hover:opacity-90 transition-opacity text-rosso  hover:bg-rosso hover:text-white font-semibold w-full py-2 rounded"
                                >
                                    Segnala
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Segnala;
