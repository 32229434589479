import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 123.25 123.25"
      fill="CurrentColor"
      width={w}
      >
      <g data-name="layer 1">
        <path
        fill="CurrentColor"
        d="M61.63 0C27.65 0 0 27.65 0 61.63s27.65 61.63 61.63 61.63 61.63-27.65 61.63-61.63S95.61 0 61.63 0zm0 117.25C30.96 117.25 6 92.3 6 61.62S30.95 6 61.63 6s55.63 24.95 55.63 55.63-24.95 55.63-55.63 55.63z"
        className="cls-1"
        ></path>
        <path
        fill="CurrentColor"
          d="M61.25 86.21c-1.59 0-2.91.56-3.91 1.66-.99 1.09-1.49 2.35-1.49 3.74s.5 2.65 1.49 3.74c1 1.1 2.32 1.66 3.91 1.66s2.91-.56 3.91-1.66c.99-1.09 1.49-2.35 1.49-3.74s-.5-2.65-1.49-3.74c-1-1.1-2.32-1.66-3.91-1.66zM78.08 39.58c-.96-2.05-2.27-3.8-3.89-5.2-1.62-1.4-3.55-2.47-5.75-3.18-2.19-.71-4.55-1.07-7-1.07-4.82 0-8.94 1.4-12.25 4.15-2 1.67-4.48 5.33-5.05 8.66-.32 1.88.96 3.67 2.84 4 1.64.29 3.23-.63 3.83-2.19.7-1.83 1.71-4.1 2.78-5.15 1.92-1.89 4.47-2.85 7.59-2.85 1.51 0 2.93.23 4.23.7 1.29.46 2.44 1.13 3.42 1.99.97.85 1.75 1.93 2.33 3.19.57 1.26.86 2.72.86 4.33 0 2.25-.48 4.15-1.42 5.63-.96 1.52-2.19 2.88-3.68 4.07l-2.55 2.28c-.91.85-1.81 1.72-2.69 2.6s-1.72 1.92-2.43 3.03c-.72 1.12-1.29 2.41-1.69 3.81-.4 1.4-.61 2.98-.61 4.72v2.27c0 2.03 1.65 3.68 3.68 3.68 2.03 0 3.68-1.65 3.68-3.68v-1c0-1.59.12-2.96.35-4.07.23-1.09.61-2.09 1.12-2.98.52-.89 1.21-1.75 2.05-2.57.86-.83 1.93-1.77 3.2-2.79 1.15-.97 2.26-1.94 3.28-2.87a17.03 17.03 0 002.76-3.23c.78-1.18 1.4-2.54 1.83-4.04.43-1.49.65-3.25.65-5.22 0-2.6-.49-4.96-1.45-7.02z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;