export const calcolaDistanza = async (lat1, lon1, lat2, lon2) => {
    var raggioTerra = 6371;
    var lat1Rad = toRadians(lat1);
    var lon1Rad = toRadians(lon1);
    var lat2Rad = toRadians(lat2);
    var lon2Rad = toRadians(lon2);

    var deltaLat = lat2Rad - lat1Rad;
    var deltaLon = lon2Rad - lon1Rad;

    var a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1Rad) *
        Math.cos(lat2Rad) *
        Math.sin(deltaLon / 2) *
        Math.sin(deltaLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var distanza = raggioTerra * c;
    return distanza.toFixed();
};

function toRadians(degrees) {
    return (degrees * Math.PI) / 180;
}