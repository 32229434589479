import useMeasure from "react-use-measure";
import useIsStandalone from "../../../utils/Standalone";
import {
    useDragControls,
    useMotionValue,
    useAnimate,
    motion,
} from "framer-motion";
import { IoIosClose } from "react-icons/io";

const DragCloseDrawer = ({ open, setOpen, children }) => {
    const [scope, animate] = useAnimate();
    const [drawerRef, { height }] = useMeasure();
    const isStandalone = useIsStandalone();

    const y = useMotionValue(0);
    const controls = useDragControls();

    const handleClose = async () => {
        animate(scope.current, { opacity: [1, 0] });

        const yStart = typeof y.get() === "number" ? y.get() : 0;

        await animate("#drawer", { y: [yStart, height] });

        setOpen(false);
    };

    return (
        <>
            {open && (
                <motion.div
                    ref={scope}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={handleClose}
                    className="fixed inset-0 z-[999] bg-verdeScuro/30"
                >
                    <motion.div
                        id="drawer"
                        ref={drawerRef}
                        onClick={(e) => e.stopPropagation()}
                        initial={{ y: "100%" }}
                        animate={{ y: "0%" }}
                        transition={{ ease: "easeInOut" }}
                        className={`absolute bottom-0 w-full ${isStandalone ? "min-h-[40vh] max-h-[90vh]" : "min-h-[30vh] max-h-[85vh]"} overflow-y-auto rounded-t-3xl bg-white`}
                        style={{ y }}
                        drag="y"
                        dragControls={controls}
                        onDragEnd={() => {
                            if (y.get() >= 100) {
                                handleClose();
                            }
                        }}
                        dragListener={false}
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={{ top: 0, bottom: 0.5 }}
                    >
                        <div className={`absolute ${isStandalone && "pb-5"} left-0 right-0 top-0 z-10 flex items-center justify-between bg-white p-4`}>
                            <div className="w-10"></div>
                            <button
                                onPointerDown={(e) => {
                                    controls.start(e);
                                }}
                                className="h-2 w-14 hidden cursor-grab touch-none rounded-full bg-verdeScuro active:cursor-grabbing"
                            ></button>
                            <div onClick={handleClose}>
                                <IoIosClose className="text-3xl text-grigio cursor-pointer" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center relative h-full px-4 pt-16 mb-8">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </>
    );
};

export default DragCloseDrawer;