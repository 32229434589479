import React from "react";
import ProfilePic from "../ProfilePic/ProfilePic";
import ProfilePicName from "../ProfilePic/ProfilePicName";
import Recensione from "../../../assets/Icons/Recensione";

function CardRecensioni({ item }) {
    return (
        <div className="bg-white p-3  md:min-w-[15rem] md:max-w-[18rem]  w-full flex flex-col gap-2 rounded-lg shadow-md">
            <div className="flex gap-2">
                <ProfilePic userId={item.send_by} />
                <ProfilePicName userId={item.send_by} />
            </div>
            <div className="flex gap-2 text-lg items-center font-semibold">
                <p>{item.valutazione}.0</p>
                <div className="flex gap-1">
                    {Array.from({ length: item.valutazione }).map((_, i) => {
                        return (
                            <div key={i} className="w-5 text-verde">
                                <Recensione />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="text-sm font-light mt-3">
                <p>{item.testo}</p>
            </div>
        </div>
    );
}

export default CardRecensioni;
