import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const StaggeredDropDownRegalo = ({
    titolo,
    opzioni,
    isOpen,
    setIsOpen,
    opennth,
    setChange,
    initialValue,
}) => {
    const [open, setOpen] = useState(false);
    const initialCheckedItems = opzioni.map(
        (option) => option.text === initialValue
    );
    const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

    const [disponibile, setDisponibile] = useState(initialValue || null);

    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(opennth);
    };



    return (
        <div className="flex items-center w-full  ">
            <motion.div
                animate={open ? "open" : "closed"}
                className="relative w-full"
            >
                <button
                    onClick={() => handleClick()}
                    className="flex items-center justify-between gap-2 px-3 py-2 rounded-md text-verdeScuro bg-background w-full transition-colors"
                >
                    {disponibile === null ? (
                        <span className="font-medium text-sm">{titolo}</span>
                    ) : (
                        <span>{disponibile}</span>
                    )}
                    <motion.span variants={iconVariants}>
                        <FiChevronDown />
                    </motion.span>
                </button>

                <motion.ul
                    initial={wrapperVariants.closed}
                    variants={wrapperVariants}
                    transition={{ duration: 0.001 }}
                    style={{ originY: "top", translateX: "-50%" }}
                    className="flex  z-40 flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[50%] w-full overflow-hidden"
                >
                    {opzioni.map((item, i) => {
                        return (
                            <Option
                                key={i}
                                prop={item}
                                checked={checkedItems === i}
                                onCheck={() => setCheckedItems(i)}
                                setDisponibile={setDisponibile}
                                setChange={setChange}
                                click={handleClick}
                            />
                        );
                    })}
                </motion.ul>
            </motion.div>
        </div>
    );
};

const Option = ({
    prop,
    Icon,
    checked,
    onCheck,
    setDisponibile,
    setChange,
    click
}) => {
    return (
        <motion.li
            onClick={(e) => {
                e.stopPropagation();
                onCheck(); // Call the new function when checkbox is clicked
                setDisponibile(prop.text);
                setChange(prop.text);
                click();
            }}
            className="flex z-40 relative items-center gap-2 w-full p-2 text-sm font-medium whitespace-nowrap rounded-md hover:bg-verdeScuro/10 text-slate-700 hover:text-verdeScuro transition-colors cursor-pointer"
        >
            <input
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="cursor-pointer rounded-[0.30rem] ring-0 focus:ring-0 checked:bg-verde"
                type="checkbox"
                name=""
                id=""
                checked={checked} // Set the checked state
            />
            <span>{prop.text}</span>
        </motion.li>
    );
};

export default StaggeredDropDownRegalo;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};

const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
        },
    },
    closed: {
        opacity: 0,
        y: -15,
        transition: {
            when: "afterChildren",
        },
    },
};

const actionIconVariants = {
    open: { scale: 1, y: 0 },
    closed: { scale: 0, y: -7 },
};
