import React from "react";

function BottoneGrigio({ text, onclick }) {
	return (
		<button
			type="button"
			onClick={() => onclick()}
			className="py-2 px-4 w-full text-center hover:text-verdeScuro text-white border border-verdeScuro hover:bg-verdeScuro/30 bg-verdeScuro focus:ring-4 focus:outline-none focus:ring-verdeScuro/30 font-semibold rounded-lg text-base  flex items-center justify-center"
		>
			{text}
		</button>
	);
}

export default BottoneGrigio;
