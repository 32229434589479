import { auth, db, messaging } from "./firebase";
import {
	useEffect,
	useState,
	lazy,
	Suspense,
	createContext,
	useReducer,
} from "react";
import Cookies from "universal-cookie";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Caricamento from "./Components/Atoms/Caricamento/Caricamento";
import Sidebar from "./Components/Molecules/Sidebar/Sidebar";
import Navbar from "./Components/Molecules/Sidebar/Navbar";
import Footer from "./Components/Molecules/Footer/Footer";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	updateDoc,
} from "firebase/firestore";
import Preferiti from "./Pages/Preferiti/Preferiti";
import Register from "./Pages/Register/Register";
import Prova from "./Pages/Prova/Prova";
import ProdottoAperto from "./Pages/Prodotto/ProdottoAperto";
import Search from "./Pages/Search/Search";
import Profili from "./Pages/Profili/Profili";
import globals from "./utils/Globals";
import Benvenuto from "./Components/Atoms/Modals/Benvenuto";
import React from "react";
import Registrato from "./Pages/Registrato/Registrato";
import VerificaMail from "./Components/Atoms/Modals/VerificaMail";
import Funziona from "./Pages/Funziona/Funziona";
import Chisiamo from "./Pages/Chisiamo/Chisiamo";
import Problemi from "./Pages/Problemi/Problemi";
import { getToken } from "firebase/messaging";

// Importazioni lazy

const Home = lazy(() => import("./Pages/Home/Home"));
const ProdottoApertoP = lazy(() => import("./Pages/Prodotto/ProdottoApertoP"));
const Chat = lazy(() => import("./Pages/Chat/Chat"));
const Notifications = lazy(() => import("./Pages/Notifications/Notifications"));
const Profilo = lazy(() => import("./Pages/Profilo/Profilo"));
const Login = lazy(() => import("./Pages/Login/Login"));
const Community = lazy(() => import("./Pages/Community/Community"));
const Exit = lazy(() => import("./Components/Atoms/Exit"));
const SidebarPhone = lazy(() =>
	import("./Components/Molecules/Sidebar/SidebarPhone")
);

export const ReducerContext = createContext();

function rootReducer(state, action) {
	if (action.type === "show_sidebar_phone") {
		return { ...state, showSidebarPhone: true };
	} else if (action.type === "hide_sidebar_phone") {
		return { ...state, showSidebarPhone: false };
	}
}

const Provider = ({ children, state, dispatch }) => {
	return (
		<ReducerContext.Provider value={{ state, dispatch }}>
			{children}
		</ReducerContext.Provider>
	);
};

function App() {
	const [logged, setLogged] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [user, setUser] = useState({});

	const [state, dispatch] = useReducer(rootReducer, { showSidebarPhone: true });

	//SITEMAP
	/*

    const routes = [
        { path: "/", element: <Home logged={false} /> },
        { path: "/funzionamento", element: <Funziona logged={false} /> },
        { path: "/chisiamo", element: <Chisiamo /> },
        { path: "/contatti", element: <Contatti /> },
        { path: "/login", element: <Login /> },
        { path: "/register", element: <Register /> },
    ];

    function generateSitemap() {
        const baseUrl = "https://togiftit.com"; // Sostituisci con il tuo URL
        const sitemapContent =
            '<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n<url>\n<loc>https://togiftit.com/ads.txt</loc>\n</url>';

        const urls = routes.map(
            (route) => `<url><loc>${baseUrl}${route.path}</loc></url>`
        );

        const sitemap = sitemapContent + urls.join("\n") + "\n</urlset>";

        console.log(sitemap);
    }
*/
	useEffect(() => {
		// Il listener viene chiamato ogni volta che lo stato di autenticazione cambia
		const unsubscribe = auth.onAuthStateChanged(async (utente) => {
			try {
				if (utente) {
					const docRef = doc(db, "users", utente.uid);
					const docData = await getDoc(docRef);

					console.log(window.location.pathname);

					if (!docData.exists() && window.location.pathname !== "/register") {
						window.location.href = "/register";
					} else {
						setLogged(true);
						const livello =
							docData.data().livello && docData.data().livello > 0
								? docData.data().livello
								: 0;
						setUser({
							...docData.data(),
							id: utente.uid,
							livello: livello,
						});
						console.log("Livello: ", user);
						globals.user = {
							...docData.data(),
							id: utente.uid,
							livello: livello,
						};
					}
				} else {
					// Utente non autenticato
					setUser(null);
					setLogged(false);
				}
			} catch (error) {
				console.error("Error fetching user data:", error);
			} finally {
				setLoaded(true);
			}
		});

		// Pulizia del componente per evitare perdite di memoria
		return () => unsubscribe();
	}, []);

	// Disabilita la selezione di testo su tutti gli elementi eccetto h1, h2, h3, h4, h5, h6, p, a, span, li, label
	document.addEventListener("DOMContentLoaded", function () {
		const elements = document.querySelectorAll(
			"*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p):not(a):not(span):not(li):not(label)"
		);
		elements.forEach((element) => {
			element.classList.add("no-select");
		});
	});

	const ww = window.innerWidth;

	////////////////////////////////////// CONSOLE CLEAR ******************************************************
	useEffect(() => {
		//console.clear();
	});

	const fullScreen = ["login", "register"].includes(
		window.location.pathname.split("/")[1]
	)
		? true
		: false;

	const [notificationToken, setNotificationToken] = useState("");
	/*
    async function requestPermission() {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            // Generate Device Token for notification
            const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID_KEY,
            });
            setNotificationToken(token);
            console.log("Token Gen", token);
        } else if (permission === "denied") {
            console.log("Denied for the notification");
        }
    }*/
	useEffect(() => {
		//requestPermission();
	}, []);

	return (
		<Provider state={state} dispatch={dispatch}>
			<div className="flex flex-col h-screen">
				<Navbar user={user} logged={logged} />
				<div
					className={`${
						!fullScreen && "mt-navbar-mobile mb-bottombar-phone"
					} flex-1 md:mt-navbar-web  md:mb-0 overflow-auto`}
				>
					{loaded && (
						<div className="hidden md:block">
							<Sidebar logged={logged} user={user} loaded={loaded} />
						</div>
					)}
					{loaded ? (
						<div className={`${!fullScreen && "h-full px-4 md:ml-sidebar"}`}>
							<BrowserRouter>
								{logged ? (
									<Routes>
										<Route
											path="/"
											element={<Home logged={logged} user={user} />}
										/>
										<Route
											path="/prodotto/:id"
											element={<ProdottoApertoP user={user} logged={logged} />}
										/>
										<Route
											path="/dashboard/:id"
											element={<ProdottoAperto user={user} />}
										/>
										<Route path="/chat/:chat" element={<Chat />} />
										<Route path="/chat" element={<Chat />} />
										<Route path="/notifications" element={<Notifications />} />
										<Route
											path="/favourites"
											element={<Preferiti user={user} userId={user.id} />}
										/>

										<Route path="/profilo" element={<Profilo user={user} />} />
										<Route
											path="/profilo/:id"
											element={<Profili user={user} />}
										/>
										<Route path="/search" element={<Search user={user} />} />

										<Route path="/logout" element={<Exit />} />
										<Route
											path="/registrato"
											element={<Registrato user={user} />}
										/>
										<Route path="/faq" element={<Funziona />} />
										<Route path="/chisiamo" element={<Chisiamo />} />
										<Route path="/register" element={<Register />} />
										<Route path="*" element={<Navigate to="/" replace />} />
									</Routes>
								) : (
									<Routes>
										<Route path="/" element={<Home />} />
										<Route path="/login" element={<Login />} />
										<Route path="/register" element={<Register />} />
										<Route path="/faq" element={<Funziona />} />
										<Route path="/chisiamo" element={<Chisiamo />} />
										<Route path="/search" element={<Search />} />
										<Route path="/problemi" element={<Problemi />} />
										<Route path="/prodotto/:id" element={<ProdottoApertoP />} />
										<Route
											path="/registrato"
											element={<Registrato user={user} />}
										/>
										<Route
											path="*"
											element={<Navigate to="/login" replace />}
										/>
									</Routes>
								)}
							</BrowserRouter>
							<Footer />
							{logged && <Benvenuto user={user} />}
						</div>
					) : (
						<div className="h-[100vh]">
							<Caricamento />
						</div>
					)}
				</div>
				<div className="fixed md:hidden block z-50 w-full bottom-0 left-0">
					<SidebarPhone user={user} />
				</div>
			</div>
		</Provider>
	);
}

export default App;

const Redirect = ({ path }) => {
	window.location.href = path;
	return <Navigate to={path} replace />;
};
