import axios from "axios";

export async function uploadImage(imageFile) {
    const formData = new FormData();
    formData.append("img", imageFile);

    const res =  await axios.post(
            "https://server.togiftit.com/api/uploadprofilepicture",
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                },
            }
    );

    return res.data.url;
}