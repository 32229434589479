import React from "react";

function FileUploadBtn() {
	return (
		<div className="flex w-full justify-center items-center md:aspect-square">
			<div className="bg-verde p-1 w-12 h-12 flex justify-center items-center rounded-lg">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="30"
					height="56"
					viewBox="0 0 30 56"
				>
					<text
						fill="#fbfbfc"
						data-name="+"
						fontFamily="ArialMT, Arial"
						fontSize="50"
						transform="translate(0 45)"
					>
						<tspan x="0" y="0">
							+
						</tspan>
					</text>
				</svg>
			</div>
		</div>
	);
}

export default FileUploadBtn;
