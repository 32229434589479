import React from "react";
import LogoBianco from "../../../assets/Logos/LogoBianco";

function FooterSite() {
    const y = new Date().getFullYear();

    (function (w, d) {
        var loader = function () {
            var s = d.createElement("script"),
                tag = d.getElementsByTagName("script")[0];
            s.src = "https://cdn.iubenda.com/iubenda.js";
            tag.parentNode.insertBefore(s, tag);
        };
        if (w.addEventListener) {
            w.addEventListener("load", loader, false);
        } else if (w.attachEvent) {
            w.attachEvent("onload", loader);
        } else {
            w.onload = loader;
        }
    })(window, document);

    return (
        <div className=" bottom-0 md:block hidden right-0 w-full">
            <footer className="bg-verde md:pb-5 shadow">
                <div className="w-full  mx-auto p-4 md:py-4">
                    <div className="flex md:flex-row md:grid md:grid-cols-3 w-full items-center  mb-5 ">
                        <div className="md:w-[150px] w-44">
                            <LogoBianco />
                        </div>

                        <div className="flex items-center  justify-center ">
                            <div className="flex space-x-5 items-center justify-center ">
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://www.facebook.com/profile.php?id=100091961577076"
                                        )
                                    }
                                    className="text-white w-12 h-12  rounded-full p-3 transition-all duration-150 hover:-translate-y-2"
                                >
                                    <svg
                                        className=" fill-white"
                                        width="25px"
                                        height="25px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                        ></g>
                                        <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H15V13.9999H17.0762C17.5066 13.9999 17.8887 13.7245 18.0249 13.3161L18.4679 11.9871C18.6298 11.5014 18.2683 10.9999 17.7564 10.9999H15V8.99992C15 8.49992 15.5 7.99992 16 7.99992H18C18.5523 7.99992 19 7.5522 19 6.99992V6.31393C19 5.99091 18.7937 5.7013 18.4813 5.61887C17.1705 5.27295 16 5.27295 16 5.27295C13.5 5.27295 12 6.99992 12 8.49992V10.9999H10C9.44772 10.9999 9 11.4476 9 11.9999V12.9999C9 13.5522 9.44771 13.9999 10 13.9999H12V21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
                                                className=""
                                            ></path>{" "}
                                        </g>
                                    </svg>
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://instagram.com/togiftit/"
                                        )
                                    }
                                    className="text-white w-12 h-12  rounded-full p-3 transition-all duration-150 hover:-translate-y-2"
                                >
                                    <svg
                                        fill="#ffffff"
                                        className="fill-white "
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        width="25px"
                                        height="25px"
                                        viewBox="0 0 169.063 169.063"
                                        xspace="preserve"
                                        stroke="#ffffff"
                                    >
                                        <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                        ></g>
                                        <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <g>
                                                {" "}
                                                <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752 c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407 c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752 c17.455,0,31.656,14.201,31.656,31.655V122.407z"></path>{" "}
                                                <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561 C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561 c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"></path>{" "}
                                                <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78 c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78 C135.661,29.421,132.821,28.251,129.921,28.251z"></path>{" "}
                                            </g>{" "}
                                        </g>
                                    </svg>
                                </button>
                                <button
                                    onClick={() =>
                                        window.open(
                                            "https://tiktok.com/@togiftit"
                                        )
                                    }
                                    className="text-white w-12 h-12  rounded-full p-3 transition-all duration-150 hover:-translate-y-2"
                                >
                                    <svg
                                        className="fill-white"
                                        fill="#ffffff"
                                        width="25px"
                                        height="25px"
                                        viewBox="0 0 32 32"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                        ></g>
                                        <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>{" "}
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <ul className="flex flex-wrap items-center md:justify-end justify-center md:mb-6 text-sm font-medium text-white sm:mb-0 text-center">
                            <li>
                                <p
                                    rel="noreferrer"
                                    className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe  "
                                    onClick={() =>
                                        window.open(
                                            "https://www.iubenda.com/privacy-policy/67170133"
                                        )
                                    }
                                >
                                    Privacy Policy
                                </p>
                            </li>
                            <li className="w-5">|</li>
                            <li>
                                <p
                                    rel="noreferrer"
                                    className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe "
                                    onClick={() =>
                                        window.open(
                                            "https://www.iubenda.com/privacy-policy/67170133/cookie-policy"
                                        )
                                    }
                                >
                                    Cookie Policy
                                </p>{" "}
                            </li>
                            <li className="w-5">|</li>
                            <li>
                                <p
                                    rel="noreferrer"
                                    onClick={() => {
                                        window.open(
                                            "https://www.iubenda.com/termini-e-condizioni/67170133"
                                        );
                                    }}
                                    className="iubenda-nostyle cursor-pointer hover:underline iubenda-noiframe iubenda-embed iubenda-noiframe"
                                >
                                    Termini e Condizioni
                                </p>
                            </li>
                        </ul>
                    </div>
                    <span className="flex justify-center  text-sm text-white sm:text-center">
                        © {y}{" "}
                        <a
                            href="https://togiftit.com"
                            className="hover:underline ml-2"
                        >
                            {" "}
                            Togiftit™
                        </a>
                    </span>
                </div>
            </footer>
        </div>
    );
}

export default FooterSite;
