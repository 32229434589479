import Seme from "../../assets/Livelli/Seme";
import Germoglio from "../../assets/Livelli/Germoglio";
import Arbusto from "../../assets/Livelli/Arbusto";



export const lvl = {
    0: { regali: 0, nome: "Seme", img: () => <Seme /> },
    1: { regali: 1, nome: "Germoglio", img: () => <Germoglio /> },
    2: { regali: 3, nome: "Arbusto", img: () => <Arbusto /> },
    3: { regali: 10, nome: "Albero" },
    4: { regali: 20, nome: "Foresta" },
    5: { regali: 30, nome: "Giungla" },
};
