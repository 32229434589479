import React from "react";
import Animali from "../../../assets/categorie/Animali";
import Bellezza from "../../../assets/categorie/Bellezza";
import Casa from "../../../assets/categorie/Casa";
import Elettrodomestici from "../../../assets/categorie/Elettrodomestici";
import Faidate from "../../../assets/categorie/Faidate";
import Moda from "../../../assets/categorie/Moda";
import Giardino from "../../../assets/categorie/Giardino";
import Mobili from "../../../assets/categorie/Mobili";
import Sport from "../../../assets/categorie/Sport";
import Informatica from "../../../assets/categorie/Informatica";
import Tempolibero from "../../../assets/categorie/Tempolibero";
import Bimbi from "../../../assets/categorie/Bimbi";

const cats = [
    {
        macro: "mobili",
        img: <Mobili/>,
        micro: [
            "Tavoli",
            "Divani",
            "Poltrone",
            "Sedie e altre sedute",
            "Mobili per ufficio",
            "Piccoli mobili contenitori",
            "Grandi mobili contenitori",
            "Biancheria da letto",
            "Mobili da cucina",
            "Altri mobili",
        ],
    },
    {
        macro: "casa",
        img:  <Casa/>,
        micro: [
            "Stoviglie",
            "Utensili da cucina",
            "Padelle e pentole",
            "Decorazioni murali",
            "Oggetti decorativi",
            "Lampade",
            "Tappeti",
            "Tende e tapparelle",
            "Biancheria per la casa",
            "Contenitori",
            "Manutenzione e pulizia",
            "Altri articoli per la casa",
            "Buoni regalo e coupon",
        ],
    },
    {
        macro: "giardino ed esterni",
        img:  <Giardino/>,
        micro: [
            "Arredi esterni",
            "Piante",
            "semi e giardinaggio",
            "Vasi per fiori",
            "Tagliaerba e attrezzi da giardino motorizzati",
            "Utensili da giardino",
            "Barbecue e piastre",
            "Piscine e spa",
            "Giochi da esterno",
            "Legna e ceppi",
            "Pietre, macerie, materiali da riempimento",
            "Altri articoli per esterni",
        ],
    },
    {
        macro: "fai da te",
        img:  <Faidate/>,
        micro: [
            "Strumenti manuali",
            "Strumenti elettrici",
            "Ferramenta",
            "Materiali per pittura",
            "Materiali idraulici",
            "Materiali elettrici",
            "Materiali da costruzione",
            "Legno, tavole e pallet",
            "Falegnameria",
            "Manutenzione e attrezzature per veicoli",
            "Cartoni",
        ],
    },
    {
        macro: "elettrodomestici",
        img:  <Elettrodomestici/>,
        micro: [
            "Frigoriferi e congelatori",

            "Lavastoviglie",
            "Lavatrici e asciugatrici",
            "Cucine",
            "Piani cottura",
            "Cappe",
            "Forni e microonde",

            "Preparazione di bevande",
            "Elettrodomestici da cucina e robot",
            "Aspirapolvere e lavapavimenti",
            "Riscaldamento e climatizzazione",
            "Altri elettrodomestici",
        ],
    },
    {
        macro: "tecnologia ed elettronica",
        img:  <Informatica/>,
        micro: [
            "Televisori",
            "Computer e tablet",
            "Scanner e stampanti",
            "Caricabatterie e cavi",
            "Accessori informatici",
            "Telefoni",
            "Accessori telefonici",
            "Cuffie e auricolari",
            "Sistemi audio",
            "Fotografia e video",
            "Altri dispositivi elettronici",
        ],
    },
    {
        macro: "abbigliamento",
        img:  <Moda/>,
        micro: [
            "Abbigliamento donna",
            "Scarpe donna",
            "Accessori donna",
            "Abbigliamento uomo",
            "Scarpe uomo",
            "Accessori uomo",
            "Abbigliamento 10-16",
            "Scarpe bambino",
            "Accessori bambino",
            "Gioielli",
            "Valigie e borse",
        ],
    },
    {
        macro: "bellezza",
        img:  <Bellezza/>,
        micro: [
            "Prodotti per l'igiene e la cura",
            "Trucco",
            "Profumi",
            "Prodotti per l'igiene femminile",
            "Accessori di bellezza",
            "Dispositivi di bellezza elettrici",
        ],
    },
    {
        macro: "tempo libero, giochi e cultura",
        img:  <Tempolibero/>,
        micro: [
            "Libri",
            "DVD, Blu-ray e VHS",
            "CD e vinili",
            "Giochi da tavolo e puzzle",
            "Videogiochi e console",
            "Giocattoli e peluche",
            "Cartoleria e forniture",
            "Arti plastiche",
            "Merceria",
            "Strumenti musicali",
            "Travestimenti e feste",
            "Biglietti per eventi",
        ],
    },
    {
        macro: "bambini e neonati",
        img:  <Bimbi/>,
        micro: [
            "Passeggini e altre sedute",
            "Lettini e culle",
            "Igiene e pannolini",
            "Giocattoli e apprendimento",
            "Abbigliamento 0-5",
            "Abbigliamento 5-10",
            "Prodotti per la maternità",
            "Cameretta",
            "Biberon e accessori",
            "Prodotti per l'alimentazione dei bambini",
            "Altre attrezzature per bambini",
        ],
    },
    {
        macro: "sport e mobilità",
        img:  <Sport/>,
        micro: [
            "Biciclette",
            "Monopattini, roller e skateboard",
            "Altri veicoli",
            "Attrezzature per fitness",
            "Palloni",
            "Sport con racchetta",
            "Attrezzature per montagna",
            "Prodotti per piscina e spiaggia",
            "Materiali e accessori sportivi",
            "Abbigliamento sportivo",
            "Scarpe sportive",
        ],
    },
    {
        macro: "amici animali",
        img:  <Animali/>,
        micro: [
            "Cibo per animali",
            "Giocattoli per animali",
            "Accessori per animali",
            "Attrezzature per animali",
            "Prodotti per l'igiene e la salute degli animali",
            "Altro per animali",
        ],
    },
];

export default cats;
