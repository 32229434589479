import React from "react";

function Icon({ w = 25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 145.39 169.84"
      width={w}
    >
      <path
        d="M101.4 126.19c-2.1 0-4.19-.54-6.06-1.66-4.91-2.93-6.99-8.4-5.3-13.95.53-1.74 1.61-3.51 3.05-5 2.45-2.54 4.98-5.05 7.42-7.49l.47-.47H21.95c-7.63 0-12.99-5.02-13.02-12.2-.03-6.32 5.05-11.66 11.31-11.91 1.72-.07 3.42-.05 5.07-.04.66 0 1.32.01 1.99.01h42.54c10.4 0 20.81 0 31.21-.01-.81-.81-1.62-1.61-2.44-2.42-1.85-1.83-3.77-3.72-5.61-5.66-2.96-3.12-4.17-7.36-3.24-11.33.94-4 3.81-7.16 7.87-8.65 3.13-1.15 7.89-1.51 12.79 3.33 2.12 2.1 4.22 4.21 6.33 6.31l2.46 2.46c1.84 1.84 3.68 3.68 5.53 5.52 4.36 4.35 8.86 8.84 13.27 13.3 5.59 5.67 5.62 12.67.06 18.29-8.12 8.21-16.94 17.05-27.77 27.84-2.46 2.45-5.69 3.73-8.9 3.73zM81.7 90.4H107c.81 0 1.49.17 1.99.29.11.03.23.06.34.08 2.36.46 3.62 3.06 2.51 5.2-.05.09-.08.19-.12.28-.24.56-.6 1.41-1.36 2.18-1.58 1.6-3.17 3.18-4.75 4.76-2.42 2.41-4.93 4.91-7.33 7.39-.63.66-1.15 1.46-1.34 2.09-.72 2.37.05 4.43 2.1 5.66 1.94 1.16 4.42.76 6.17-.99 10.82-10.77 19.63-19.6 27.73-27.8 2.8-2.83 2.78-5.27-.07-8.16-4.38-4.44-8.88-8.93-13.23-13.26a1441.6 1441.6 0 01-5.53-5.53l-2.46-2.47c-2.09-2.1-4.19-4.2-6.29-6.28-2.35-2.32-3.96-2.16-5.23-1.69-1.75.64-2.97 1.93-3.34 3.53-.38 1.63.15 3.35 1.46 4.73 1.76 1.86 3.63 3.7 5.44 5.49.96.95 1.92 1.9 2.87 2.85 1.15 1.15 2.28 2.31 3.53 3.58l1.86 1.89c2.09 2.13.84 5.73-2.12 6.1-.37.05-.68.09-.95.13-.61.09-1.18.18-1.84.18-12.4.01-24.8.01-37.2.02H25.26c-1.64-.01-3.18-.03-4.73.04-2.35.09-4.4 2.28-4.39 4.68.01 3.15 2.18 5.03 5.81 5.03H81.7z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;