import React from "react";

function Icon({ w = 25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 179.7 184.21"
      width={w}
    >
      <path
        fill="CurrentColor"
        d="M159.19 131.29c-7.9-14.22-16.62-33.82-16.62-46.14 0-44.14-24.14-57.15-40.21-60.95v-5.28c0-6.91-5.6-12.51-12.52-12.51s-12.51 5.6-12.51 12.51v5.28C61.26 27.99 37.12 41 37.12 85.15c0 12.33-8.71 31.92-16.62 46.14-3.79 6.81 1.16 15.16 8.96 15.16h120.78c7.79 0 12.74-8.35 8.96-15.16zM113.42 154.93H66.27c.42 12.69 10.78 22.87 23.57 22.87s23.15-10.18 23.57-22.87z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;