import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 150.88 128.05"
      width={w}
    >
      <path
      fill="CurrentColor"
        d="M102.32 127.29c-2.05 0-3.99-.97-5.23-2.67l-21.32-21.16H24.48c-12.47 0-22.62-10.15-22.62-22.62V23.39C1.85 10.91 12 .76 24.48.76h101.94c12.47 0 22.62 10.15 22.62 22.62v57.45c0 12.47-10.15 22.62-22.62 22.62h-17.53v17.3c0 2.95-1.9 5.47-4.74 6.28-.61.17-1.22.26-1.83.26zM80.81 98.3l20.87 20.71v-17.73a5.04 5.04 0 015.03-5.03h19.71c8.5 0 15.41-6.92 15.41-15.42V23.38c0-8.5-6.91-15.41-15.41-15.41H24.48c-8.5 0-15.42 6.91-15.42 15.41v57.45c0 8.5 6.92 15.42 15.42 15.42h52.16c1.66 0 3.18.76 4.17 2.05zm27.91-30.78c-8.13 0-14.75-6.62-14.75-14.75s6.61-14.75 14.75-14.75 14.75 6.61 14.75 14.75-6.61 14.75-14.75 14.75zm0-22.29c-4.16 0-7.54 3.38-7.54 7.54s3.38 7.54 7.54 7.54 7.54-3.38 7.54-7.54-3.38-7.54-7.54-7.54zM75.44 67.52c-8.13 0-14.75-6.62-14.75-14.75s6.62-14.75 14.75-14.75 14.75 6.61 14.75 14.75-6.61 14.75-14.75 14.75zm0-22.29c-4.16 0-7.54 3.38-7.54 7.54s3.38 7.54 7.54 7.54 7.54-3.38 7.54-7.54-3.38-7.54-7.54-7.54zM42.16 67.52c-8.13 0-14.75-6.62-14.75-14.75s6.61-14.75 14.75-14.75 14.75 6.61 14.75 14.75-6.61 14.75-14.75 14.75zm0-22.29c-4.16 0-7.54 3.38-7.54 7.54s3.38 7.54 7.54 7.54 7.54-3.38 7.54-7.54-3.38-7.54-7.54-7.54z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;