import { Player } from "@lottiefiles/react-lottie-player";

const Caricamento = () => {
    return (
        <div className="p-10">
            <Player
                autoplay
                loop
                src={require("../../../assets/animation/loading.json")}
                className=" w-32 md:h-72 p-10 mt-10"
            ></Player>
        </div>
    );
};

export default Caricamento;