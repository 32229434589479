import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 179.7 184.21"
      width={w}
    >
      <path
      fill="CurrentColor"
        d="M25.83 79.16v92.24c0 1.62 1.32 2.94 2.94 2.94h122c1.62 0 2.94-1.31 2.94-2.94V79.16c0-1.62 1.31-2.94 2.94-2.94h10.72c2.33 0 3.36-2.93 1.55-4.38L92.8 10.52a2.931 2.931 0 00-3.65-.03L10.82 71.82c-1.85 1.44-.82 4.41 1.52 4.41H22.9c1.62 0 2.94 1.32 2.94 2.94zm104.26 26.15a5.384 5.384 0 01-3.89 1.65h-.28c-2.38 0-4.33-1.79-4.54-4.16-.25-2.88-1.44-5.16-3.44-6.65l-.22-.16c-.31-.23-.61-.47-.96-.65-.28-.14-.56-.26-.87-.38-.94-.36-1.74-.54-2.49-.57s-.31 0-.31 0c-.34 0-.7.04-1.13.13l-.34.08c-.37.09-.74.2-1.11.33-.4.14-.76.3-1.13.5-.08.04-.15.1-.23.16-.11.08-.22.16-.34.24-2.48 1.59-3.89 4.06-3.89 6.78 0 1.2-.45 2.34-1.25 3.15-.76.77-1.79 1.19-2.87 1.19h-.66c-1.49 0-2.88-.59-3.91-1.67a5.396 5.396 0 01-1.5-3.98c.44-9.95 8.55-17.74 18.44-17.74s18.1 7.82 18.44 17.8c.05 1.48-.49 2.88-1.52 3.94zm1.2 19.74c-5.25 18.5-22.32 31.42-41.51 31.42s-36.26-12.92-41.51-31.42c-.4-1.41-.12-2.9.78-4.08.91-1.21 2.31-1.9 3.82-1.9h73.83c1.51 0 2.91.69 3.82 1.91.89 1.18 1.17 2.67.77 4.08zM47.93 101.4l.02-.28c.43-9.81 8.53-17.54 18.42-17.54s17.99 7.73 18.43 17.6v.21c.02.15.01.3 0 .45-.02.32-.03.48-.06.64l-.09.37c-.08.32-.13.51-.2.7l-.14.29c-.09.2-.2.43-.34.65l-.18.24c-.1.15-.28.39-.48.6l-.16.15c-.24.23-.44.4-.67.55-.59.41-1.25.69-1.96.84l-.28.04c-.21.04-.52.08-.83.08h-.66c-2.06 0-3.78-1.54-4.07-3.66a3.09 3.09 0 01-.09-.5c-.25-2.88-1.44-5.16-3.44-6.65l-.34-.25c-.23-.15-.38-.26-.54-.37-.04-.03-.13-.09-.23-.14-.31-.16-.65-.32-1.02-.45-.36-.13-.74-.23-1.11-.33l-.32-.07c-.45-.09-.8-.13-1.15-.13h-.14c-.34 0-.7.04-1.13.13l-.35.08c-.37.09-.74.2-1.11.33-.37.13-.7.29-1.04.46-.07.04-.16.1-.26.16-.1.07-.25.18-.41.28l-.43.32c-1.99 1.48-3.18 3.76-3.42 6.6-.02.25-.06.44-.1.64-.28 2.03-2 3.56-4.06 3.56h-.66c-.32 0-.62-.04-.92-.09-.9-.17-1.56-.46-2.15-.87-.25-.18-.48-.37-.69-.57l-.11-.1c-.22-.23-.4-.47-.57-.71l-.13-.18c-.12-.19-.24-.43-.35-.66l-.12-.25c-.08-.2-.13-.41-.18-.61-.06-.2-.09-.33-.11-.45-.03-.16-.04-.32-.04-.49-.01-.14-.02-.26-.03-.36v-.07-.16z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;