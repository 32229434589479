import axios from "axios";
import React, { useState } from "react";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";

import BottoneVerde from "../../Components/Atoms/Bottoni/BottoneVerde";
import BottoneGrigio from "../../Components/Atoms/Bottoni/BottoneGrigio";

function Problemi() {
    const [mail, setMail] = useState("");
    const [loading, setLoading] = useState(false);
    const [res, setres] = useState("");

    const verifica = async () => {
        setLoading(true);
        const headers = {
            "Access-Control-Allow-Origin": "*",
        };
        await axios
            .post(
                process.env.REACT_APP_API_URL + "/auth/sendverifica",
                {
                    mail,
                },
                headers
            )
            .then((response) => {
                setTimeout(() => setLoading(false), 2000);
                setres(response.data.message);
            });
    };
    const respwd = async () => {
        setLoading(true);
        const headers = {
            "Access-Control-Allow-Origin": "*",
        };
        await axios
            .post(
                process.env.REACT_APP_API_URL + "/mail/resetpassword",
                {
                    email: mail,
                },
                {headers}
            )
            .then((response) => {
                setTimeout(() => setLoading(false), 2000);
                setres(response.data.message);
            });
    };

    return (
        <div className="w-full px-7 min-h-[80vh] flex justify-center items-center md:mt-24 mt-32 ">
            {loading ? (
                <Caricamento />
            ) : !res ? (
                <div className="mt-12">
                    {" "}
                    <h2 className="md:text-6xl text-4xl text-center mb-5 text-verdeScuro font-bold">
                        Problemi di accesso?
                    </h2>
                    <p>
                        Se non ricordi la password inserisci la tua mail e ti verrà inviato un link per resettarla
                    </p>
                    <div>
                        <div className="flex flex-col p-12 justify-center items-center space-y-5">
                            <input
                                className="rounded-md mb-5"
                                value={mail}
                                onChange={(e) => setMail(e.currentTarget.value)}
                                type="email"
                                name=""
                                id=""
                                placeholder="Inserisci la tua mail"
                            />

                            <div
                                className="
                            "
                            >
                                <BottoneGrigio
                                    onclick={respwd}
                                    text={"Reset password"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="py-16 px-12">
                        <div>
                            {res}
                            <br />
                            <a
                                className="text-blue-500 underline"
                                href="/login"
                            >
                                {" "}
                                Torna al login
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Problemi;
