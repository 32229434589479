import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Livello 2"
      viewBox="0 0 157.65 157.66"
    >
      <g data-name="layer 1">
        <path
          d="M138.57 5.75h-6.19v-.44c0-2.92-2.38-5.3-5.3-5.3s-5.3 2.38-5.3 5.3v.44h-13.53v-.44c0-2.92-2.38-5.3-5.3-5.3s-5.3 2.38-5.3 5.3v.44H84.12v-.44c0-2.92-2.38-5.3-5.3-5.3s-5.3 2.38-5.3 5.3v.44H59.99v-.44c0-2.92-2.38-5.3-5.3-5.3s-5.3 2.38-5.3 5.3v.44H35.86v-.44c0-2.92-2.38-5.3-5.3-5.3s-5.3 2.38-5.3 5.3v.44h-6.19C8.56 5.75 0 14.31 0 24.83v113.74c0 10.52 8.56 19.09 19.09 19.09h119.48c10.52 0 19.08-8.56 19.08-19.09V24.83c0-10.52-8.56-19.09-19.08-19.09zm8.49 27.83v104.99c0 4.68-3.81 8.49-8.49 8.49H19.09c-4.68 0-8.49-3.81-8.49-8.49V33.58h136.46z"
          className="cls-1"
        ></path>
        <path
          d="M29.54 131.27h22.89c1.09 0 1.97-.88 1.97-1.97v-19.37c0-1.09-.88-1.97-1.97-1.97H29.54c-1.09 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM67.38 131.27h22.89c1.09 0 1.97-.88 1.97-1.97v-19.37c0-1.09-.88-1.97-1.97-1.97H67.38c-1.08 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM29.54 102.17h22.89c1.09 0 1.97-.88 1.97-1.97V80.83c0-1.09-.88-1.97-1.97-1.97H29.54c-1.09 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM67.38 102.17h22.89c1.09 0 1.97-.88 1.97-1.97V80.83c0-1.09-.88-1.97-1.97-1.97H67.38c-1.08 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM105.23 102.17h22.89c1.09 0 1.97-.88 1.97-1.97V80.83c0-1.09-.88-1.97-1.97-1.97h-22.89c-1.09 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM67.38 73.08h22.89c1.09 0 1.97-.88 1.97-1.97V51.74c0-1.08-.88-1.97-1.97-1.97H67.38c-1.08 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97zM105.23 73.08h22.89c1.09 0 1.97-.88 1.97-1.97V51.74c0-1.08-.88-1.97-1.97-1.97h-22.89c-1.09 0-1.97.88-1.97 1.97v19.37c0 1.09.88 1.97 1.97 1.97z"
          className="cls-1"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;