import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 165.89 170.29"
      width={w}
    >
      <path
      fill="CurrentColor"
        d="M158.71 66.54c-.79-2.44-2.16-4.52-3.9-6.15-2.6-2.45-6.07-3.88-9.92-3.88h-31.57c-2.94 0-5.52-1.87-6.42-4.67l-9.76-30.03c-1.19-3.65-3.63-6.51-6.76-8.23-2.09-1.15-4.49-1.81-7.06-1.81s-4.96.66-7.05 1.8c-3.13 1.72-5.57 4.58-6.76 8.23l-9.76 30.03a6.723 6.723 0 01-6.41 4.67H21.76c-3.84 0-7.31 1.44-9.91 3.88a14.529 14.529 0 00-3.9 6.15c-.79 2.44-.91 4.92-.46 7.26.67 3.51 2.63 6.72 5.74 8.98l25.55 18.56a6.734 6.734 0 012.45 7.55l-9.76 30.02c-1.19 3.66-.89 7.4.63 10.63 1.02 2.16 2.57 4.1 4.65 5.61 2.08 1.51 4.4 2.38 6.77 2.68 3.55.45 7.19-.43 10.3-2.69l25.54-18.55a6.723 6.723 0 017.93 0l25.55 18.56c2.6 1.88 5.56 2.83 8.54 2.83.59 0 1.18-.07 1.77-.14 2.37-.3 4.69-1.18 6.76-2.69a14.46 14.46 0 004.64-5.6c1.52-3.23 1.82-6.98.63-10.63l-9.75-30.02c-.91-2.79.08-5.82 2.45-7.55l25.54-18.56c3.11-2.26 5.07-5.46 5.74-8.97.45-2.35.33-4.82-.46-7.26zm-33.36 82.32c-1.91 1.39-5.01 2.13-7.93 0l-25.55-18.57c-2.56-1.84-5.54-2.77-8.53-2.77s-5.99.93-8.54 2.78l-25.54 18.56c-2.91 2.13-6.01 1.4-7.93 0s-3.57-4.11-2.46-7.55l9.76-30.02c1.94-6-.17-12.53-5.28-16.24L17.8 76.49c-2.92-2.12-3.18-5.29-2.45-7.54.74-2.25 2.81-4.67 6.42-4.67h31.58c6.31 0 11.86-4.04 13.81-10.04l9.76-30.03c1.12-3.44 4.05-4.66 6.41-4.66s5.31 1.22 6.42 4.66l9.76 30.03c1.94 6 7.49 10.04 13.81 10.04h31.57c3.62 0 5.69 2.41 6.42 4.66.74 2.25.47 5.43-2.45 7.55l-25.54 18.56a14.488 14.488 0 00-5.28 16.24l9.75 30.02c1.12 3.45-.53 6.16-2.45 7.55z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;