import React from "react";

function Icon({w=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="layer 1"
      viewBox="0 0 150.88 128.05"
      width={w}
      fill="CurrentColor"
    >
      <path
        fill="CurrentColor"
        d="M127.67 2.89H23.22c-10.77 0-19.49 8.73-19.49 19.49v58.86c0 10.76 8.73 19.49 19.49 19.49h53.45c.59 0 1.14.3 1.45.81l22.36 22.19c1.59 2.56 5.54 1.43 5.54-1.58v-19.96c0-.81.65-1.46 1.46-1.46h20.19c10.76 0 19.49-8.73 19.49-19.49V22.38c0-10.76-8.72-19.49-19.49-19.49zM41.34 62.86c-6.31 0-11.42-5.11-11.42-11.42s5.11-11.42 11.42-11.42 11.42 5.11 11.42 11.42-5.11 11.42-11.42 11.42zm34.1 0c-6.31 0-11.42-5.11-11.42-11.42s5.11-11.42 11.42-11.42 11.42 5.11 11.42 11.42-5.11 11.42-11.42 11.42zm34.1 0c-6.31 0-11.42-5.11-11.42-11.42s5.11-11.42 11.42-11.42 11.42 5.11 11.42 11.42-5.11 11.42-11.42 11.42z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Icon;