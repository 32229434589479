import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

function ProfilePicName({ userId, key = 0, full = false, textStyle }) {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const getUser = async () => {
        try {
            setLoaded(false);
            const docRef = doc(db, "users", userId);
            const docSnap = await getDoc(docRef);
            setUser(docSnap.data());
            setLoaded(true);
        } catch (error) {
            console.log("cazzo");
        }
    };

    useEffect(() => {
        getUser();
    }, [key]);

    return (
        <>
            {loaded && user && (
                <div className="flex items-center space-x-2">
                    <p className={textStyle || "text-black md:text-sm text-xs"}>{user.nome}</p>
                </div>
            )}
        </>
    );
}

export default ProfilePicName;
