import {
    collection,
    doc,
    getDocs,
    onSnapshot,
    query,
    where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import Caricamento from "../../Components/Atoms/Caricamento/Caricamento";

function Preferiti({ user, userId }) {
    const [preferiti, setPreferiti] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchLiked = async () => {
        if (!userId) {
            setPreferiti([]);
        }

        const docRef = doc(db, "preferiti", userId);
        const unsubscribe = onSnapshot(
            docRef,
            async (docSnap) => {
                if (docSnap.exists()) {
                    const fav = docSnap.data().list || [];
                    setFavourites(docSnap.data().list || []);
                    if (fav.length > 0) {
                        const docsRef = query(
                            collection(db, "regali"),
                            where("__name__", "in", fav)
                        );
                        const querySnapshot = await getDocs(docsRef);
                        setPreferiti(
                            querySnapshot.docs.map((doc) => ({
                                id: doc.id,
                                ...doc.data(),
                            }))
                        );
                    } else {
                        setPreferiti([]);
                    }
                } else {
                    console.log("Il documento non esiste");
                    setPreferiti([]);
                }
            },
            (error) => {
                return;
            }
        );
        setLoaded(true);
        return () => {
            unsubscribe();
        };
    };

    useEffect(() => {
        fetchLiked();
    }, []);

    return (
        <div className="grid min-h-[80vh] mt-5 xl:grid-cols-5 lg:grid-cols-4  md:grid-cols-3 grid-cols-2 md:gap-5 gap-3">
            {loaded ? (
                preferiti.length ? (
                    preferiti.map((item, i) => {
                        return (
                            <div className="" key={i}>
                                <CardPublic
                                    user={user}
                                    props={item}
                                    key={i}
                                    liked={favourites.includes(item.id)}
                                />
                            </div>
                        );
                    })
                ) : (
                    <div className="flex w-full col-span-full justify-center items-start md:items-center mt-20 text-center h-96 text-xl ">
                        <p>
                            Non hai ancora aggiunto nessun regalo ai preferiti
                        </p>
                    </div>
                )
            ) : (
                <Caricamento />
            )}
        </div>
    );
}

export default Preferiti;
