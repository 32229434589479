import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Profilo from "../../../assets/Profilo";
import { lvl } from "../../../Pages/Profilo/lvl";

function ProfilePic({ userId, full = false, w = 8 }) {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const getUser = async () => {
        try {
            const docRef = doc(db, "users", userId);
            const docSnap = await getDoc(docRef);
            setUser(docSnap.data());
            setTimeout(() => {
                setLoaded(true);
            }, 200);
        } catch (error) {
            console.log("cazzo");
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            {loaded && user ? (
                <div
                    className={`relative z-0 inline-flex ${
                        full ? "bg-rosso" : "bg-transparent"
                    } items-center justify-center w-${w} aspect-square`}
                >
                    <div className="relative w-full h-full overflow-hidden border rounded-full">
                        {user.img ? (
                            <img
                                className="object-cover w-full h-full"
                                src={user.img}
                                alt=""
                            />
                        ) : (
                            <Profilo />
                        )}
                    </div>
                    <div
                        id="lvl"
                        className="absolute w-[40%] z-10 -right-[5%] -bottom-[5%]"
                    >
                        {
                            user.livello !== undefined && lvl[user.livello]
                            && lvl[user.livello].img()
                            
                        }
                    </div>
                </div>
            ) : (
                //TODO: Fix placeholder
                <div
                    className={`w-${w} h-${w} bg-grigio bg-opacity-20 rounded-full`}
                />
            )}
        </>
    );
}

export default ProfilePic;
