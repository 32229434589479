import React from "react";
 
import BottoneRosso from "../../Components/Atoms/Bottoni/BottoneRosso";

function Registrato() {
    const params = new URLSearchParams(window.location.search);
    const google = params.get("google");
    return (
        <div className="flex  justify-center relative w-full h-[80vh]">
            <div className=" pr-7 pl-1">
                <p className="text-verdeScuro font-bold text-center mt-24 mb-5 font-malik text-4xl">
                    Ti sei registrato correttamente!
                </p>
                <div className={"p-8 text-center"}>
                    <p>
                        Congratulazioni per essere entrato a far parte della nostra community!
                    </p>
                    {google === "false" && <p>
                        Controlla la casella di posta della mail inserita in
                        fase di registrazione e conferma la mail cliccando il
                        link
                    </p>}
                    <p className="hidden">
                        <br />
                        (Se non riesci a trovare la mail controlla anche nello{" "}
                        <b>spam</b>, <br /> se non la trovi neanche li puoi visitare la
                        pagina "Problemi" accessibile da{" "}
                        <a className="underline text-verde" href="/problemi">
                            qui
                        </a>
                        )
                    </p>
                </div>
                <div className="w-full mt-12 flex justify-center">
                    <BottoneRosso
                        onclick={() => (window.location.href = "/login")}
                        text={"Procedi al login"}
                    />
                </div>
            </div>
        </div>
    );
}

export default Registrato;
