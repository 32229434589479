import {
    collection,
    doc,
    getDoc,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import CardPublic from "../../Components/Atoms/Card/CardPublic";
import StaggeredDropDown from "../../Components/Molecules/Dropdown/StaggeredDropDown";
import DropdownLocation from "../../Components/Molecules/Dropdown/DropdownLocation";
import CategorieDropdown from "../../Components/Molecules/Dropdown/CategorieDropdown";
import { calcolaDistanza } from "../../utils/calcolaDistanza";

function Search({ user = null }) {
    const [regali, setRegali] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [search, setSearch] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [distance, setDistance] = useState("");
    const [categoria, setCategoria] = useState("");
    const [preferiti, setPreferiti] = useState([]);
    const disponibilita = [
        { text: "Infrasettimanale di giorno" },
        { text: "Infrasettimanale di sera" },
        { text: "Fine settimana" },
        { text: "Flessibile" },
    ];

    const getRegali = async (lat, lng, distance, categoria, search) => {
        try {
            const giftsRef = collection(db, "regali");

            let constraints = [orderBy("createdAt", "desc"), limit(500)];

            if (categoria)
                constraints.push(where("categoria", "==", categoria));

            // Calculate bounding box
            const latNum = parseFloat(lat);
            const lngNum = parseFloat(lng);
            const distNum = parseFloat(distance); // Keep the distance in km
            const latDelta = distNum * (180 / Math.PI);
            const lngDelta =
                (distNum * (180 / Math.PI)) /
                Math.cos((latNum * Math.PI) / 180);

            const lowerLat = latNum - latDelta;
            const upperLat = latNum + latDelta;
            const lowerLng = lngNum - lngDelta;
            const upperLng = lngNum + lngDelta;

            // Add bounding box constraints
            constraints.push(where("lat", ">=", lowerLat));
            constraints.push(where("lat", "<=", upperLat));
            constraints.push(where("lng", ">=", lowerLng));
            constraints.push(where("lng", "<=", upperLng));

            const queri = query(giftsRef, limit(500));

            const unsubscribe = onSnapshot(queri, async (querySnapshot) => {
                let giftsPromises = querySnapshot.docs.map(async (item) => {
                    const data = item.data();
                    const ownerData = await getCoordinate(data.owner);

                    if (!ownerData) return null;

                    const giftLat = ownerData.lat;
                    const giftLng = ownerData.lng;
                    const dist = await calcolaDistanza(
                        latNum,
                        lngNum,
                        giftLat,
                        giftLng
                    );

                    if (dist <= distance) {
                        return { ...data, id: item.id };
                    }
                    return null;
                });

                let gifts = await Promise.all(giftsPromises);
                gifts = gifts.filter((gift) => gift !== null);

                if (search.length > 2 && search !== "null") {
                    gifts = gifts.filter((gift) =>
                        gift.titolo.toLowerCase().includes(search.toLowerCase())
                    );
                }
                setRegali(gifts);
                setLoaded(true);
                console.log("Gifts", gifts);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error getting documents:", error);
            return () => {};
        }
    };

    const getCoordinate = async (owner) => {
        const docRef = doc(db, "users", owner);
        const docData = await getDoc(docRef);
        if (docData.exists()) {
            return { lat: docData.data().lat, lng: docData.data().lng };
        }
        return null;
    };

    const fetchLiked = async () => {
        if (!user.id) {
            //setPreferiti([]);
            console.log("Utente non loggato");
            return;
        }

        const docRef = doc(db, "preferiti", user.id);
        const unsubscribe = onSnapshot(
            docRef,
            (docSnap) => {
                if (docSnap.exists()) {
                    setPreferiti(docSnap.data().list || []);
                    console.log(docSnap.data().list);
                } else {
                    console.log("Il documento non esiste");
                    setPreferiti([]);
                }
            },
            (error) => {
                return;
            }
        );

        return () => {
            unsubscribe();
        };
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setSearch(params.get("search") || "");
        setLat(params.get("lat") || "");
        setLng(params.get("lng") || "");
        setDistance(params.get("distance") || "");
        setCategoria(
            params.get("categoria").length && (params.get("categoria") || "")
        );

        const lat = params.get("lat");
        const lng = params.get("lng");
        const distance = params.get("distance");

        getRegali(
            lat,
            lng,
            distance,
            params.get("categoria"),
            params.get("search")
        );
        fetchLiked();
    }, []);

    useEffect(() => {
        console.log(search);
    });

    const [isOpen, setIsOpen] = useState(0);
    const handleSetIsOpen = (dropdownNumber) => {
        setIsOpen((prevIsOpen) =>
            prevIsOpen === dropdownNumber ? 0 : dropdownNumber
        );
    };
    return (
        <div>
            <div className="flex gap-5 mt-1">
                <DropdownLocation
                    isOpen={isOpen === 1}
                    setIsOpen={handleSetIsOpen}
                    opennth={1}
                    city="Torino"
                />
                <StaggeredDropDown
                    isOpen={isOpen === 2}
                    setIsOpen={handleSetIsOpen}
                    opennth={2}
                    titolo="Condizioni"
                    opzioni={[
                        { text: "Come nuovo" },
                        { text: "Buone" },
                        { text: "Accettabili" },
                        { text: "Da aggiustare" },
                    ]}
                />

                <CategorieDropdown
                    isOpen={isOpen === 3}
                    setIsOpen={handleSetIsOpen}
                    opennth={3}
                />
                <StaggeredDropDown
                    isOpen={isOpen === 4}
                    setIsOpen={handleSetIsOpen}
                    opennth={4}
                    titolo="Disponibilità"
                    opzioni={disponibilita}
                />
            </div>

            <div className="text-lg mt-10 text-black w-full grid grid-cols-4 gap-3">
                {loaded &&
                    regali.map((regalo, i) => (
                        <div key={i}>
                            <CardPublic
                                props={regalo}
                                user={user}
                                liked={preferiti.includes(regalo.id)}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default Search;
