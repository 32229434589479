import {
    addDoc,
    collection,
    doc,
    limit,
    orderBy,
    query,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import axios from "axios";
import { postNotification } from "./NotificationsUtils";
import globals from "./Globals";

/**
 * @typedef {Object} Chat
 * @property {string} lastMessage
 * @property {string} completeName
 * @property {string} itemName
 * @property {string} itemId
 * @property {boolean} isBooking
 * @property {boolean} bookingAccepted
 * @property {boolean} confirmedByOwner
 * @property {boolean} confirmedByReceiver
 * @property {string} receiver
 * @property {string} owner
 * @property {string} userId
 * @property {boolean} canceled
 * @property {string} id
 */

/**
 *
 * @param {string} receiver
 * @param {string} owner
 * @param {string} itemId
 * @returns {string}
 */
export function getChatFolder(receiver, owner, itemId) {
    return receiver + ":" + owner + ":" + itemId;
}

export function lastMessageQuery(chatId) {
    return query(
        collection(db, "new_chats/" + chatId + "/messages"),
        orderBy("createdAt", "desc"),
        limit(1)
    );
}

export async function setReadMessage(messageRef) {
    await updateDoc(messageRef, {
        read: true,
    });
}

export function renderTime(rawTime) {
    const time = rawTime.toDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

export function renderDate(rawTime) {
    const time = rawTime.toDate();
    const day = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    return (
        (day < 10 ? "0" + day : day) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        year
    );
}

/**
 * @param {string} ownerId - The owner of the item
 * @param {*} item The item the chat refers to
 * @param {boolean} isBooking If true, the caller is requesting to book the item from user, if false, the caller is just requesting info
 */
export async function initChat(ownerId, item, isBooking = false) {
    await setDoc(
        doc(
            db,
            "new_chats",
            getChatFolder(auth.currentUser.uid, ownerId, item.id)
        ),
        {
            participants: [auth.currentUser.uid, ownerId],
            itemName: item.titolo,
            itemId: item.id,
            owner: ownerId,
            receiver: auth.currentUser.uid,
            isBooking: isBooking,
            bookingAccepted: false,
            canceled: false,
            confirmedByOwner: false,
            confirmedByReceiver: false,
        },
        { merge: true }
    );
    if (isBooking) {
        await postNotification(ownerId, {
            title: globals.user.nome + " " + globals.user.cognome,
            content: "Mi sono prenotato al tuo regalo: " + item.titolo,
            sender: auth.currentUser.uid,
            type: "prenotazione",
            link:
                "/chat/" + getChatFolder(auth.currentUser.uid, ownerId, item.id),
        });
    }
}

/**
 * Sets a chat status as canceled.
 * It can be invoked both by the owner of the item and the receiver.
 * The chat is not deleted.
 * @param {*} owner
 * @param {*} receiver
 * @param {*} item
 */
export async function cancelChat(owner, receiver, item) {
    await updateDoc(
        doc(db, "new_chats", getChatFolder(receiver, owner, item)),
        {
            canceled: true,
        }
    );
}

/**
 *
 * @param {string} owner
 * @param {string} receiver
 * @param {string} item
 * @param {string} receiverName
 * @param {Chat|null} chat
 */
export async function confirmItemReceived(
    owner,
    receiver,
    item,
    receiverName,
    chat = null
) {
    //TODO: Add field update on present document
    //TODO: Make requests atomic
    await updateDoc(
        doc(db, "new_chats", getChatFolder(receiver, owner, item)),
        {
            confirmedByReceiver: true,
        }
    );
    await updateDoc(doc(db, "regali", item), {
        ricevuto: true,
    });
    
    await postNotification(
        owner,
        {
            title: receiverName,
            content: "Ho ricevuto il tuo regalo!",
            sender: receiver,
            
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

/**
 *
 * @param {string} owner
 * @param {string} receiver
 * @param {string} item
 * @param {string} ownerName
 * @param {Chat|null} chat
 */
export async function confirmItemDelivered(
    owner,
    receiver,
    item,
    ownerName,
    chat = null
) {
    //TODO: Make requests atomic
    await updateDoc(
        doc(db, "new_chats", getChatFolder(receiver, owner, item)),
        {
            confirmedByOwner: true,
        }
    );
    await updateDoc(doc(db, "regali", item), {
        consegnato: true,
    });
    postNotification(
        receiver,
        {
            title: ownerName,
            content: "Ho consegnato il tuo regalo.",
            sender: owner,
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

/**
 * @param {string} chatId
 * @param {string} sendTo
 * @param {string} content
 * @param {string|null} imageUrl
 * @param {boolean} isAutomatic
 */
export async function sendMessage(
    chatId,
    sendTo,
    content,
    imageUrl,
    isAutomatic = false
) {
    let messageData = {};

    console.log(chatId);
    console.log(sendTo);

    messageData = {
        ...messageData,
        sendBy: auth.currentUser.uid,
        sendTo: sendTo,
        content: content,
        read: false,
        //TODO: Message timestamp should be set server-side
        createdAt: new Date(),
        imageUrl: imageUrl,
        isAuto: isAutomatic,
    };

    await addDoc(
        collection(db, "new_chats/" + chatId + "/messages"),
        messageData
    );
}
